export const deprecationMessage = (widgetName: string) => {
  // @formatter:off
  const message = `
 --------------------------------------------------
 __      ____ _ _ __ _ __ (_)_ __   __ _
 \\ \\ /\\ / / _\` | '__| '_ \\| | '_ \\ / _\` |
  \\ V  V / (_| | |  | | | | | | | | (_| |
   \\_/\\_/ \\__,_|_|  |_| |_|_|_| |_|\\__, |
                                   |___/
  -----------------------------------------------
  You're using a deprecated picker/widget
  -----------------------------------------------
              PLEASE TAKE ACTION
  -----------------------------------------------
  ${widgetName} is now deprecated please use the 
  latest version from: 
      https://shared-uat.fr.world.socgen/ic-widgets/
  or check the documentation:
      https://sgithub.fr.world.socgen/pages/ic/ic-widgets-docs/                   
  -----------------------------------------------
  Contact: PAR-GBSU-CLD-CLI-ICT-WEB                        
  -----------------------------------------------                                   
  `;
  // tslint:disable-next-line
  console.error(message);
};
