import { Component, h } from "preact";
import { isEmpty } from "lodash";
import {
  InvalidFeedback,
  getErrorClass,
} from "Sgb4/common/Components/InvalidFeedback";
import { ErrorProps } from "Sgb4/DeprecatedTagPicker/common/typings";

export interface LabelValue<T> {
  label: string;
  value: T;
}

export interface SelectProps<T> extends ErrorProps {
  options: Array<LabelValue<T | string>>;
  onChange: (selectedOption: T) => void;
  initValue?: T;
  id?: string;
  name?: string;
  disabled?: boolean;
  resetValue?: boolean;
  className?: string;
  placeholder?: string;
}

export interface SelectState<T> {
  selectedOption: undefined | T | string;
}

export class Select<T> extends Component<SelectProps<T>, SelectState<T>> {
  constructor(props: SelectProps<T>) {
    super(props);
    this.state = {
      selectedOption:
        this.props.initValue && !isEmpty(this.props.options)
          ? this.props.initValue
          : !this.props.initValue && !isEmpty(this.props.options)
          ? this.props.options[0].value
          : undefined,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  public componentDidUpdate(prevProps: SelectProps<T>) {
    if (prevProps.initValue !== this.props.initValue) {
      this.setState({ selectedOption: this.props.initValue });
    }
  }

  public render({
    options,
    disabled,
    className,
    inError,
    errorMessage,
    placeholder,
  }: SelectProps<T>) {
    return (
      <div>
        <select
          className={`form-control ${getErrorClass(inError)} ${className ||
            ""}`}
          onChange={this.handleOnChange}
          disabled={disabled}
          value={this.state.selectedOption as string}
        >
          {!isEmpty(placeholder) && (
            <option value="" disabled selected>
              {placeholder}
            </option>
          )}
          {options.map((option, index) => (
            <option key={index} value={option.value as string}>
              {option.label}
            </option>
          ))}
        </select>

        {inError && <InvalidFeedback errorMessage={errorMessage} />}
      </div>
    );
  }

  private handleOnChange(event: any) {
    this.setState({ selectedOption: event.target.value });
    this.props.onChange(event.target.value);
  }
}
