import { ClientOutput } from "common/types/account";
import { OwnersCdbDto, CountryCdb } from "Sgb4/common/types/contact.typings";
/**
 * Represents a contact (usually used for our props or our events).
 *
 * This is the format standardised for SG-Dashboard communication between selectors and widgets.
 */
export type ContactOutput = ClientOutput & {
  id?: string;
  jobTitle?: string;
  firstname?: string;
  lastname?: string;
  fullName?: string;
  team?: string;
  email?: string;
  scope?: Scope;
  isPepOrSpo?: boolean;
  country?: CountryCdb;
};

export interface Scope {
  isIcUser?: boolean; // only in CRM
  isSgGroupContact?: boolean; // only in CRM
  isInternal?: boolean; // FIXME only isInternal is available in contactDB.
}

export interface AccountId {
  id?: string;
  level?: string;
}

export interface AddressDto {
  street1?: string;
  street2?: string;
  street3?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  countryIso3?: string;
  countryIso2?: string;
  priority?: string;
}

export interface CivilityDto {
  title?: string;
  gender: string;
  jobTitle: string;
  jobType: string;
  birthDate?: Date;
  type?: string;
}

export interface ClientReferenceDto {
  internalId?: AccountId;
  bdrCommercialId?: AccountId;
  bdrLegalId?: AccountId;
  bdrMnemonic?: string;
  name?: string;
}

/**
 * Contact
 */
export type ReadContactDto = ContactDto & { id: string };

export interface ContactDto {
  id?: string;
  lastname?: string;
  firstname?: string;
  isActive?: boolean;
  mergedTo?: string;
  clientBasic?: ClientReferenceDto;
  clientFull?: ClientReferenceDto;
  civility?: CivilityDto;
  mainEmail?: EmailDto;
  emails?: EmailDto[];
  mainPhone?: PhoneDto;
  phones?: PhoneDto[];
  mainAddress?: AddressDto;
  addresses?: AddressDto[];
  organizationTeam?: OrganizationTeamDto;
  visibility?: VisibilityDto;
  coverage?: CoverageDto[];
  severity?: SeverityDto;
  scope?: Scope;
  department?: string;
  linkedin?: string;
  owners?: OwnersCdbDto;
  isPeporsPo?: boolean;
  igg?: string;
}

export interface CoverageDto {
  person: UserDto;
  role?: string;
  function?: string;
  startDate?: Date;
}

export interface CreateContactDto {
  clientFull: ClientReferenceDto;
  civility: CivilityDto;
  emails: EmailDto[];
  visibility: VisibilityDto;
  coverage: SimpleCoverageDto[];
  id?: string;
  phones?: SimplePhoneDto[];
  addresses?: SimpleAddressDto[];
  severity?: SeverityDto;
}

export interface Mifid2ContactInformation {
  status: string;
  url?: string;
  isImpacted?: boolean;
}

export const defaultMidif2ContactInformation: Mifid2ContactInformation[] = [
  { status: "unknown" },
];

export interface Mifid2ContractInfo {
  mifid2Status: string;
  mifid2Url?: string;
  mifid2ContractStatus: Mifid2ContractStatus;
}

export type Mifid2ContractStatus = "none" | "pending" | "refused" | "accepted";

export interface EmailDto {
  email: string;
  type: string;
}

export interface OrganizationTeamDto {
  /**
   * Organization team id
   */
  id?: string;
  /**
   * Organization team path
   */
  path?: string;
  /**
   * Organization team id of the parent team
   */
  parentOrganizationTeamId?: string;
}

export interface PhoneDto {
  phone: string;
  countryCodeIso2: string;
  type: string;
  priority?: string;
}

export interface SeverityDto {
  severity: string;
}

export interface SimpleAddressDto {
  street1?: string;
  street2?: string;
  street3?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  type: string;
}

export interface SimpleCoverageDto {
  person: SimpleUserDto;
}

export interface SimplePhoneDto {
  phone: string;
  countryCodeIso2: string;
  type: string;
}

export interface SimpleUserDto {
  email: string;
}

export interface UserDto {
  firstname?: string;
  lastname?: string;
  email: string;
}

export interface CustomList {
  id?: string;
  name: string;
}

export interface VisibilityDto {
  visibility: string;
}

export type Properties =
  | "clientBasic"
  | "clientFull"
  | "civility"
  | "firstname"
  | "lastname"
  | "mainEmail"
  | "emails"
  | "mainPhone"
  | "phones"
  | "mainAddress"
  | "addresses"
  | "visibility"
  | "coverage"
  | "severity"
  | "team"
  | "owners"
  | "portfolio"
  | "scope";

export interface SearchCriteria {
  customListId?: string;
  search?: string;
  ids?: string[];
  supervisedByContactId?: string;
  coveredByEmail?: string;
  clientId?: string;
  clientlegal?: string;
  clientcomm?: string;
  clientmnemonic?: string;
  name?: string;
  email?: string;
  inherit?: boolean;
  organizationTeamId?: string;
  after?: Date;
  before?: Date;
  onlyActive?: boolean;
  showRestricted?: boolean;
  pageNumber?: number;
  pageSize?: number;
  properties?: string;
  isIcUser?: boolean;
  isInternal?: boolean;
  sort?: "smartSort" | "default";
}

export const searchProperties: Properties[] = [
  "clientFull",
  "mainEmail",
  "emails",
  "scope",
  "civility",
];

export type DisplayOption = "email" | "jobtitle" | "none";
export type CreateLinkMode = "redirect" | "emit" | "none";
