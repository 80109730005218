import { CountryDto } from "common/types/account";
import { AddressDto, EmailDto, PhoneDto } from "common/types/contact";
import { ContactFormStep, InputContact } from "ic-contact-editor/types";
import { find, isEmpty, uniqBy } from "lodash";

import { EmailTypes } from "ic-contact-editor/constants/presets";
import { InputMandate } from "ic-mandate-creator/component/MandateCreator/types";

import { ValidationMessageError } from "ic-contact-editor/constants/constants";

import { safeValidateNumber } from "ic-contact-editor/api/phoneApi";
import { getCountryIso2 } from "ic-contact-editor/reducer/reducer";
const defaultEmailPattern = /^([\w.%'+_-]+@[\w.-]+\.[A-Za-z]{2,})$/;
const linkedinPattern = /(http|https):\/\/(\w{2,3})\.linkedin\.com\/(in|profile|pub)\/.*/;

export const isValidMail = (mail: string) =>
  mail === "" || defaultEmailPattern.test(mail);

// tslint:disable:interface-name
export interface ErrorMessage {
  index: number;
  message?: string;
}

export interface ContactFormValidation {
  isValid: boolean;
  civility: boolean;
  firstname: boolean;
  lastname: boolean;
  account: boolean;
  mainEmail: string;
  jobtitle: boolean;
  jobtype: boolean;
  linkedInUrl: boolean;
  phones: boolean;
  addresses: boolean;
  mandates: boolean;
  mandateErrors: string[];
  emailsErrors: ErrorMessage[];
  serverError: string;
}

export const getValidation = (
  step: ContactFormStep,
  formValidation: ContactFormValidation,
  inputContact: InputContact,
  countries: CountryDto[]
): ContactFormValidation => {
  const hasMandateBusinessErrors = formValidation
    ? formValidation.mandateErrors.length > 0
    : false;
  const mainEmail = isEmpty(inputContact.mainEmail)
    ? ValidationMessageError.MAIN_MAIL_MANDATORY
    : isValidMail(inputContact.mainEmail)
    ? ""
    : ValidationMessageError.MAIN_MAIL_FORMAT;
  const validation = {
    account: step === "check" || !isEmpty(inputContact.account),
    addresses: step === "check" || isValidAddresses(inputContact.addresses),
    civility: !isEmpty(inputContact.civility),
    emailsErrors: isValidEmails(inputContact.emails),
    firstname: !isEmpty(inputContact.firstname),
    isValid: true,
    jobtitle: step === "check" || !isEmpty(inputContact.jobtitle),
    jobtype: step === "check" || !isEmpty(inputContact.jobtype),
    lastname: !isEmpty(inputContact.lastname),
    linkedInUrl: step === "check" || isValidLinkedin(inputContact.linkedInUrl),
    mainEmail,
    mandateErrors:
      step === "check"
        ? []
        : hasMandateBusinessErrors
        ? formValidation.mandateErrors
        : [],
    mandates:
      step === "check" ||
      (isValidMandates(inputContact.mandates) && !hasMandateBusinessErrors),
    phones:
      step === "check" ||
      isValidPhone(
        inputContact.phones,
        inputContact.addresses,
        inputContact.country,
        countries
      ),
    serverError: "",
  };
  const isValid = isValidForm(validation);
  return { ...validation, isValid } as ContactFormValidation;
};

export const isValidForm = (validation: ContactFormValidation) => {
  return (
    validation.civility &&
    validation.firstname &&
    validation.lastname &&
    isEmpty(validation.mainEmail) &&
    validation.account &&
    validation.jobtitle &&
    validation.jobtype &&
    validation.linkedInUrl &&
    isEmpty(validation.emailsErrors.filter(e => !isEmpty(e.message))) &&
    validation.phones &&
    validation.addresses &&
    validation.mandates &&
    isEmpty(validation.serverError)
  );
};

export const isValidLinkedin = (linkedin: string = "") =>
  linkedin === "" || linkedinPattern.test(linkedin);

export const isValidPhone = (
  phones: PhoneDto[] = [],
  addresses: AddressDto[],
  country: CountryDto | undefined,
  countries: CountryDto[]
) => {
  return (
    isValidPhoneTypes(phones) &&
    phones.every(
      ({ phone }) =>
        !isStringEmptyOrNull(phone) &&
        safeValidateNumber(phone, getCountryIso2(country, addresses, countries))
    )
  );
};

export const isValidPhoneTypes = (phones: PhoneDto[] = []) =>
  uniqBy(phones, ({ phone }) => phone).length === phones.length;

export const isValidEmails = (emails: EmailDto[] = []): ErrorMessage[] => {
  if (
    emails.length > 0 &&
    uniqBy(emails, ({ email }) => email).length !== emails.length
  ) {
    return emails.map((_, index) => ({
      index,
      message: ValidationMessageError.OPTIONAL_EMAIL_DUPLICATE_VALUES,
    }));
  }

  return emails.map(({ email, type }, index) => {
    const emailType = find(EmailTypes, item => item.value === type);
    if (!emailType) {
      return {
        index,
        message: ValidationMessageError.OPTIONAL_EMAIL_MANDATORY_TYPE,
      };
    }

    const emailWithoutDomain =
      emailType.domains.length > 0 ? (email || "").split("@")[0] : email;
    return !emailType.pattern.test(emailWithoutDomain)
      ? { index, message: ValidationMessageError.OPTIONAL_EMAIL_FORMAT }
      : { index };
  });
};

export const isValidMandates = (mandates: InputMandate[] = []) =>
  mandates.reduce(
    (isValid, mandate) =>
      isValid &&
      !isEmpty(mandate.accountId) &&
      !isEmpty(mandate.businessFunctionId),
    true
  );

export const isValidAddresses = (addresses: AddressDto[] = []) =>
  addresses.reduce(
    (isValid, address) =>
      isValid &&
      !isStringEmptyOrNull(address.street1) &&
      !isStringEmptyOrNull(address.zipCode) &&
      !isStringEmptyOrNull(address.city) &&
      !isStringEmptyOrNull(address.country) &&
      !isStringEmptyOrNull(address.countryIso3),
    true
  );

export const isStringEmptyOrNull = (value?: string) =>
  !value || value.trim() === "";
