import { IBus } from "@sg-widgets/platform-api/lib/bus";

export const getSgConnectToken = (bus: IBus) =>
  new Promise<string | null>(resolve => {
    return bus
      ? resolve(
          bus.dangerouslyGetCurrentValue<string>("sg-connect.access-token")
        )
      : resolve(null);
  });
