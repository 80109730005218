import { h, Component } from "preact";

export const BulletValue = (children: any): JSX.Element => {
  return (
    <span>
      <span className="text-secondary font-weight-bold mx-1">&bull;</span>
      <span className="text-secondary font-weight-normal text-small d-inline-block">
        {children}
      </span>
    </span>
  );
};
