import { FunctionalComponent, h } from "preact";
interface Props {
  isLoading: boolean;
}
export const ListGroupItemLoader: FunctionalComponent<Props> = ({
  isLoading = false,
}: Props) => {
  return isLoading ? (
    <div className={"py-2 pl-3 list-group-item list-group-item-action "}>
      <div className="loader">{"Loading..."}</div>
    </div>
  ) : null;
};
