import { isEmpty, split, toLower, reduce, map } from "lodash";
import { IdName } from "Sgb4/newTagPicker/common/typings";
import { LabelValue } from "Sgb4/common/Components/Select/Select";

export const splitBy = (sep: string) => (s: string) => split(s, sep);

export const normalize = (t: string) => splitBy(" ")(toLower(t));

export const removeSGCIB = (name: string) => {
  const rgx = new RegExp("SGCIB/", "i");
  return name.replace(rgx, "");
};

export const getAccountId = (props: {
  account: { icId: string };
  accountId: string;
}): string | undefined | null => {
  const id = props.accountId ? props.accountId : props.account.icId;
  return isValidId(id) ? id : undefined;
};

// tslint:disable:interface-name
interface Account {
  icId: string;
}

export const getAccountIds = (props: {
  account: Account;
  accounts: Account[];
  accountIds: string[];
}): string[] => {
  const ids = !isEmpty(props.accountIds)
    ? props.accountIds
    : !isEmpty(props.accounts)
    ? props.accounts.map(account => account.icId)
    : props.account.icId
    ? [props.account.icId]
    : [];
  return ids.filter(id => isValidId(id));
};

export const getContactId = (props: {
  contact: { id: string };
  contactId: string;
}): string => {
  return props.contactId ? props.contactId : props.contact.id;
};

export interface SafePromiseResponse<TData, TError> {
  data?: TData;
  error?: TError;
  context?: any;
}

export function safePromise<TData, TError>(
  promise: Promise<TData>,
  context?: any,
  errorMapper: (e: any) => TError = _ => _
): Promise<SafePromiseResponse<TData, TError>> {
  return new Promise<SafePromiseResponse<TData, TError>>((resolve, _) => {
    promise
      .then(data => resolve({ data, context }))
      .catch(error => resolve({ error: errorMapper(error), context }));
  });
}

export const replaceInArray = <T>(arr: T[], index: number, element: T) => {
  if (index < 0 || index >= arr.length) {
    return arr;
  }
  return [...arr.slice(0, index), element, ...arr.slice(index + 1)];
};

export function isValidId(text?: string) {
  return !isEmpty(text) && text !== "undefined" && text !== "null";
}

export function mapIdNameToLabelValue<T extends string | number>(
  idNames: Array<IdName<T>>
): Array<LabelValue<T>> {
  return map(idNames, ({ id, name }) => ({ label: name, value: id }));
}
