import { Component, h } from "preact";
import { logMessage, local } from "common/logger/logger";
import { LogTypes } from "common/logger/logger.typings";

interface Props {
  widgetName: string;
  env: string;
}

export default class WidgetTracker extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    const { widgetName, env } = this.props;
    if (!local()) {
      logMessage({
        widgetName,
        name: `${widgetName} Setup`,
        feature: `ic web-component ${widgetName}`,
        event: `${widgetName}.init`,
        description: `Initialisation of the ic web-component ${widgetName}`,
        type: LogTypes.feature,
        env,
      });
    }
  }
  public render() {
    return null;
  }
}
