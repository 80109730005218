import { FunctionalComponent, h } from "preact";
import { noop } from "lodash";

interface Props {
  className?: string;
  onClear?: () => void;
}

export const InputClearButton: FunctionalComponent<Props> = ({
  className = "",
  onClear = noop,
}: Props) => (
  <button
    type="button"
    className={`btn btn-link px-1 ${className}`}
    onClick={onClear}
  >
    Clear
  </button>
);
