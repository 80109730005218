/**
 * @deprecated use logic in newTagPicker folder!
 */
import { concat, isEmpty, filter, find, map } from "lodash";
import { Status } from "common/types/global";
import { ListItemAutoCompleteStateProps } from "Sgb4/ListItemAutocomplete/ListItemAutocomplete";
import { InputTagListStateProps } from "Sgb4/InputTagList/InputTagList";
import { TagPickerProps, TagPickerState } from "./TagPicker";

export function initState<TItem>(
  props: TagPickerProps<TItem>
): TagPickerState<TItem> {
  const tagList: InputTagListStateProps<TItem> = {
    disabled: false,
    state: "empty",
    status: "init",
    tags: [],
    terms: "",
  };
  const autocomplete: ListItemAutoCompleteStateProps<TItem> = {
    isVisible: false,
    items: props.items,
    reason: "",
    status: "init" as any,
    exclude: [],
  };
  return {
    autocomplete,
    tagList,
    terms: "",
  };
}

export function onSelect<TItem extends { id: string }>(
  state: TagPickerState<TItem>,
  props: TagPickerProps<TItem>,
  item: TItem
): TagPickerState<TItem> {
  if (state.tagList.disabled) {
    return state;
  }

  const tagList = {
    ...state.tagList,
    disabled: props.capacity
      ? state.tagList.tags.length >= props.capacity - 1
      : false,
    tags: concat(state.tagList.tags, { ...(item as any), isReadonly: false }),
  };

  const autocomplete = {
    ...state.autocomplete,
    isVisible: false,
    status: "init" as Status,
    exclude: tagList.tags,
    items: filterItems(state.autocomplete.items, tagList.tags),
  };

  return { ...state, terms: "", tagList, autocomplete };
}

export function onRemove<TItem extends { id: string }>(
  state: TagPickerState<TItem>,
  props: TagPickerProps<TItem>,
  item: TItem
): TagPickerState<TItem> {
  const tags = filterItems(state.tagList.tags, [item]);
  const autocomplete: ListItemAutoCompleteStateProps<TItem> = {
    ...state.autocomplete,
    exclude: tags,
    items: filterItems(state.autocomplete.items, tags),
  };
  const tagList = {
    ...state.tagList,
    tags: tags as any,
    disabled: props.capacity ? tags.length >= props.capacity : false,
  };
  return { ...state, autocomplete, tagList };
}

export function onSearch<TItem extends { id: string }>(
  state: TagPickerState<TItem>,
  items: TItem[]
): TagPickerState<TItem> {
  return {
    ...state,
    autocomplete: {
      ...state.autocomplete,
      status: isEmpty(items) ? "empty" : "loaded",
      items: filterItems(items, state.autocomplete.exclude),
    },
  };
}

export function onClear<TItem>(
  state: TagPickerState<TItem>
): TagPickerState<TItem> {
  return {
    ...state,
    tagList: {
      ...state.tagList,
      tags: filter(state.tagList.tags, ({ isReadonly }) => isReadonly),
      disabled: false,
    },
    autocomplete: { ...state.autocomplete, exclude: [] },
  };
}

export function onItemsLoaded<TItem>(
  state: TagPickerState<TItem>,
  props: TagPickerProps<TItem>,
  items: TItem[],
  isReadonly: boolean
): TagPickerState<TItem> {
  const newTags = concat(
    state.tagList.tags,
    map(items, (item: any) => ({
      ...item,
      isReadonly,
    }))
  );
  return {
    ...state,
    tagList: {
      ...state.tagList,
      tags: newTags,
      disabled: props.capacity ? newTags.length >= props.capacity : false,
      state: "success",
      status: "loaded",
    },
  };
}
const filterItems = <TItem extends { id: string; isReadonly?: boolean }>(
  items: TItem[],
  exclude: TItem[]
) => filter(items, item => !find(exclude, ({ id }) => id === item.id));
