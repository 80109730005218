import { CivilityTypes, EmailTypeNames } from "common/constants";
import { Properties as AccountProperties } from "common/types/account";
import { Properties as ContactProperties } from "common/types/contact";

export const CivilityList = [
  { id: CivilityTypes.Mr, type: "Mr" },
  { id: CivilityTypes.Mrs, type: "Mrs" },
  { id: CivilityTypes.Ms, type: "Ms" },
];

export const TitleList = [
  { id: 1, name: "Captain" },
  { id: 2, name: "Count" },
  { id: 3, name: "Diplomate" },
  { id: 4, name: "Doctor" },
  { id: 5, name: "Engineer" },
  { id: 6, name: "Father" },
  { id: 7, name: "Frau Dr." },
  { id: 16, name: "Frau Mag." },
  { id: 28, name: "Frau Prof." },
  { id: 9, name: "General" },
  { id: 8, name: "Geometra" },
  { id: 10, name: "Her grace" },
  { id: 11, name: "Herr Dr." },
  { id: 15, name: "Herr Mag." },
  { id: 12, name: "Herr Prof." },
  { id: 13, name: "His Grace" },
  { id: 14, name: "Lord" },
  { id: 17, name: "Mayor" },
  { id: 18, name: "Monsignor" },
  { id: 19, name: "President" },
  { id: 20, name: "President of the bar" },
  { id: 21, name: "Professor" },
  { id: 22, name: "Ragioniere" },
  { id: 23, name: "Reverend" },
  { id: 24, name: "Senior" },
  { id: 25, name: "Sir" },
  { id: 26, name: "The Honorable" },
];

export const JobTypes = [
  { id: 11, name: "ADVISORY" },
  { id: 12, name: "ANALYST" },
  { id: 13, name: "ASSISTANT" },
  { id: 14, name: "CHIEF EXECUTIVE OFFICER" },
  { id: 15, name: "CHIEF FINANCIAL OFFICER" },
  { id: 16, name: "CHIEF INVESTMENT OFFICER" },
  { id: 17, name: "CHIEF OPERATION OFFICER" },
  { id: 18, name: "COMMUNICATION & MEDIA" },
  { id: 19, name: "CONSULTING" },
  { id: 28, name: "LEGAL AND TAX" },
  { id: 20, name: "MANAGEMENT" },
  { id: 21, name: "OPERATIONS" },
  { id: 22, name: "POLITICAL" },
  { id: 23, name: "PORTFOLIO MANAGEMENT" },
  { id: 25, name: "SALES AND TRADING" },
  { id: 24, name: "SENIOR MANAGEMENT" },
  { id: 29, name: "STAKEHOLDER" },
  { id: 26, name: "TREASURY & FINANCE" },
  { id: 27, name: "-" },
];

// tslint:disable:interface-name
export interface EmailType {
  id: number;
  value: string;
  label: string;
  pattern: RegExp;
  placeholder: string;
  domains: string[];
}

const emailRegexPattern = /^(?![\w.%+_-]+@bloomberg\.net)([\w.%'+_-]+@[\w.-]+\.[A-Za-z]{2,})$/;
const emailRegexWithoutDomainPattern = /^[\w._'-]+$/;

export const EmailTypes: EmailType[] = [
  {
    domains: [],
    id: 1,
    label: "Pro. email",
    pattern: emailRegexPattern,
    placeholder: "firstname.lastname@company.name",
    value: EmailTypeNames.Professional,
  },
  {
    domains: [],
    id: 2,
    label: "Personal email",
    pattern: emailRegexPattern,
    placeholder: "firstname.lastname@company.name",
    value: EmailTypeNames.Personal,
  },
  {
    domains: ["thomsonreuters.com", "reuters.com", "reuters.net"],
    id: 3,
    label: "Reuters email",
    pattern: emailRegexWithoutDomainPattern,
    placeholder: "firstname.lastname",
    value: EmailTypeNames.ReutersChat,
  },
  {
    domains: ["bloomberg.net"],
    id: 4,
    label: "Bloomberg chat",
    pattern: emailRegexWithoutDomainPattern,
    placeholder: "firstname.lastname",
    value: EmailTypeNames.BloombergChat,
  },
  {
    domains: [
      "yahoo.com",
      "yahoo.ca",
      "yahoo.cn",
      "yahoo.de",
      "yahoo.es",
      "yahoo.fr",
      "yahoo.it",
      "yahoo.co",
      "yahoo.co.id",
      "yahoo.co.in",
      "yahoo.co.jp",
      "yahoo.co.kr",
      "yahoo.co.uk",
      "yahoo.com.au",
      "yahoo.com.cn",
      "yahoo.com.hk",
      "yahoo-inc.com",
    ],
    id: 5,
    label: "Yahoo Email",
    pattern: emailRegexWithoutDomainPattern,
    placeholder: "firstname.lastname",
    value: EmailTypeNames.YahooChat,
  },
];

export interface PhoneType {
  id: number;
  value: string;
  label: string;
}

export const PhoneTypes = [
  "Business",
  "Home",
  "Mobile",
  "Reception",
  "Business Fax",
  "Pager",
  "Assistant",
];

export const accountProperties: AccountProperties[] = [
  "bdrId",
  "country",
  "clientIds",
  "addresses",
  "computedClientRootId",
];

export const contactProperties: ContactProperties[] = [
  "clientFull",
  "civility",
  "emails",
  "phones",
  "addresses",
  "visibility",
];

export const createContactProperties: ContactProperties[] = [
  "clientFull",
  "civility",
  "emails",
  "phones",
  "addresses",
  "visibility",
  "firstname",
  "lastname",
];
