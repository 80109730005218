import { Component, h } from "preact";
import { noop } from "lodash";

interface Props {
  id?: string;
  label: string;
  disabled?: boolean;
  selected?: boolean;
  selectable?: boolean;
  highlighted?: boolean;
  readonly?: boolean;
  className?: string;
  onSelectionChange?: (id: string, isSelected: boolean) => void;
  customRender?: () => JSX.Element;
}

interface State {
  selected: boolean;
}

export class ListGroupItem extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.state = { selected: props.selected != null && props.selected };
  }

  public componentWillReceiveProps?(nextProps: Props): void {
    if (nextProps.selected != null) {
      this.setState({ selected: nextProps.selected });
    }
  }

  public render(
    {
      id,
      disabled,
      selectable,
      label,
      customRender,
      highlighted = false,
      className = "",
    }: Props,
    state: State
  ): JSX.Element | null {
    const activeClassNames =
      state.selected && !disabled
        ? selectable
          ? " list-group-item-info active"
          : " active"
        : "";
    const withSelectorClassName =
      selectable && !disabled ? " list-group-item-selector" : "";
    const disabledClassName = disabled ? " disabled" : "";
    const cursorStyle = disabled ? "not-allowed" : "pointer";
    const highlightedClassName = highlighted ? "list-group-item-focus" : "";
    return (
      <div
        key={id}
        onClick={this.handleOnClick}
        style={{ cursor: cursorStyle }}
        className={`pl-3 list-group-item list-group-item-action ${withSelectorClassName} ${activeClassNames} ${disabledClassName} ${highlightedClassName} ${className}`}
      >
        {customRender ? customRender() : label}
      </div>
    );
  }

  private handleOnClick() {
    if (this.props.disabled || this.props.readonly) {
      return;
    }

    const onSelectionChange = this.props.onSelectionChange || noop;
    if (!this.props.selectable) {
      onSelectionChange(this.props.id || this.props.label, true);
      return;
    }

    this.setState(
      (state: State) => ({ selected: !state.selected }),
      () =>
        onSelectionChange(
          this.props.id || this.props.label,
          this.state.selected
        )
    );
  }
}
