import { ContactDto, ContactOutput } from "common/types/contact";
import { map, get, isEmpty } from "lodash";
import { ContactDbReadDto } from "../../common/types/contact.typings";

interface ContactOutputDto {
  detail: {
    contacts: ContactDto[];
  };
}

export const mapToContactOutputDto = (
  contacts: ContactDbReadDto[]
): ContactOutputDto => {
  return {
    detail: {
      contacts: map<ContactDbReadDto, ContactOutput>(contacts, contact => ({
        id: contact.id,
        jobTitle: contact.jobTitle,
        firstname: contact.givenName,
        lastname: contact.lastname,
        fullName: contact.fullName,
        email:
          contact.emails && !isEmpty(contact.emails)
            ? contact.emails[0].value
            : undefined,
        scope: {
          isInternal: contact.isInternal,
          isIcUser: contact.isIcUser,
          isSgGroupContact: contact.isSgGroupContact,
          isInMyPortfolio: contact.isInMyPortfolio,
        },
        icId: contact.employeeOfAccountId || "",
        bdrCommercialId: get(contact, "employeeOf.bdrCommercial.id", null),
        bdrCommercialLevel: get(
          contact,
          "employeeOf.bdrCommercial.level",
          null
        ),
        bdrLegalId: get(contact, "employeeOf.bdrLegal.id", ""),
        name: get(contact, "employeeOf.name", null),
        bdrLegalLevel: get(contact, "employeeOf.bdrLegal.level"),
        isPepOrSpo: get(contact, "isPepOrSpo", false),
        team: get(contact, "internalHrTeam"),
        country: get(contact, "country"),
        igg: get(contact, "igg"),
      })),
    },
  };
};
