import { Component, h } from "preact";
import { IStyle } from "common/types/global";

interface Props extends IStyle {
  children?: any;
  size?: string;
}

const style = `
.list-group-item-selector.active:after {
    content: "×";
    right: 18px;
    top: 6px;
    color: #1361ff!important;
}
.list-group-item-selector:after {
    position: absolute;
    content: "+";
    color: #95a1a2;
    font-size: 24px;
    right: 14px;
    top: -2px;
}
.list-group-item-focus {
  color: #303333;
  text-decoration: none;
  background-color: #f8f9fa;
}
`;

export class ListGroup extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render({ children, classNames = "", size = "md" }: Props) {
    const top =
      size === "sm" ? 20 : size === "lg" ? 40 : size === "xl" ? 48 : 32;
    return (
      <div
        class={`card card-combobox shadow-lg ${classNames}`}
        style={`position: absolute;width: 100%;right: 0px;top: ${top}px;z-index: 2000;`}
      >
        <div className="card-body p-0">
          <style type="text/css">{style}</style>
          {children}
        </div>
      </div>
    );
  }
}
