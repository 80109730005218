export const enum LogTypes {
  functional = "functional",
  technical = "technical",
  performance = "technical",
  feature = "feature",
}

export const enum LogLevels {
  debug = "debug",
  info = "info",
  warn = "warn",
  error = "error",
  fatal = "fatal",
}

export interface LogMessage {
  name: string;
  widgetName?: string;
  type: LogTypes;
  feature: string;
  event: string;
  description: string;
  level?: LogLevels;
  watchKeyOrDuration?: any;
  env: string;
}
