import { h } from "preact";

export interface ClearButtonProps {
  onClear: () => void;
}

export const ClearButton = ({ onClear }: ClearButtonProps) => (
  <div class="input-group-append input-group-merged">
    <div class="input-group-button">
      <button type="button" class="btn btn-link" onClick={onClear}>
        Clear
      </button>
    </div>
  </div>
);
