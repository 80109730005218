/**
 * @deprecated use logic in newTagPicker folder!
 */
import { TagPickerState } from "./typings";

export function onFocus<TItem>(
  state: TagPickerState<TItem>
): TagPickerState<TItem> {
  const autocomplete = {
    ...state.autocomplete,
    isVisible: true,
  };
  return { ...state, autocomplete };
}

export function onBlur<TItem>(
  state: TagPickerState<TItem>
): TagPickerState<TItem> {
  return {
    ...state,
    autocomplete: {
      ...state.autocomplete,
      isVisible: false,
    },
  };
}

export function onInput<TItem>(
  state: TagPickerState<TItem>,
  terms: string
): TagPickerState<TItem> {
  return {
    ...state,
    terms,
    autocomplete: { ...state.autocomplete, status: "loading" },
  };
}
