import { Logger } from "@dcc-cli/ic-logger/dist/types";
import { createCliLogger } from "@dcc-cli/ic-logger/dist";
import { LogTypes, LogMessage, LogLevels } from "common/logger/logger.typings";

export const local = () => window.location.hostname === "localhost";

const mapWidgetEnvToLoggerEnv = (environment: string) => {
  switch (environment) {
    case "local":
      return "LOCAL";
    case "development":
      return "HOM";
    case "homologation":
      return "HOM";
    case "iso":
      return "ISO";
    case "production":
      return "PRD";
    default:
      return "HOM";
  }
};
const icLoggerInstance = (environment: string) => {
  const env = mapWidgetEnvToLoggerEnv(environment);
  return createCliLogger("IC", env);
};

export const logError = (environment: string, error: Error) => {
  icLoggerInstance(environment).technical(
    "UnknownError",
    `${error.message}${error.stack}`,
    LogLevels.error
  );
};

export const logMessage = ({
  feature,
  event,
  type,
  name,
  widgetName,
  description,
  watchKeyOrDuration,
  level,
  env,
}: LogMessage): void => {
  const component = "ic-web-component";
  const { functional, technical, performance }: Logger = icLoggerInstance(env)
    .setCustom("feature", feature)
    .setCustom("widget", widgetName)
    .setCustom("event", event)
    .setCustom("app", component)
    .setCustom("host", location.host)
    .setCustom("from-server", "azure")
    .setCustom("sg-connect-env", env);

  switch (type) {
    case LogTypes.feature:
    case LogTypes.functional:
      functional(name, description, component);
      break;
    case LogTypes.technical:
      technical(
        name,
        description,
        level ? level : LogLevels.error,
        component,
        feature
      );
      break;
    case LogTypes.performance:
      performance(name, description, watchKeyOrDuration, component, feature);
  }
};
