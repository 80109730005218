import { Fetch } from "common/types/global";
import { HttpResponseType } from "common/api/types";
import { ApiIO } from "common/api/index";
import { SearchType } from "Sgb4/DeprecatedTagPicker/SingleSelect/TagPicker";
import {
  Env,
  getContactDbUrl,
  getPrivateServiceUrl,
  getSoaUrl,
} from "common/api/apiUrls";
import { get, isEmpty } from "lodash";
import { WidgetConfiguration } from "@sg-widgets/shared-core";
import { IBus } from "@sg-widgets/platform-api";

export const limitBeforeSearch = 2;

export enum Service {
  Soa = "soa",
  ContactDb = "contactDb",
  PrivateServices = "privateServices",
  Maestro = "maestro",
}

export interface IcServices {
  soa: string;
  contactDb: string;
  privateServices: string;
  maestro: string;
}

export interface ServiceParameters {
  icServices: IcServices;
  headers: { [x: string]: string };
  fetch: Fetch;
  userEmail: string;
  ssoV2: boolean;
  useDefaultHeaders?: boolean;
  bus: IBus;
  environment: Env;
}

export interface CheckDomainNameEmail {
  value: string;
  type: string;
  personalEmailJustificationId: number | undefined;
}

export interface CheckDomainNameParameter {
  emails: CheckDomainNameEmail[];
  employeeOfAccountId: string;
  type: string;
}

export interface ConditionCriterias {
  condition: (searchBy: SearchType) => boolean;
  value: (terms: string) => Criterias;
}

export interface Criterias {
  name?: string;
  email?: string;
  clientComm?: string;
  clientLegal?: string;
  clientRct?: string;
}

export interface ErrorApi {
  message: string;
}

export const initFetching = (
  widgetConfiguration: WidgetConfiguration,
  soaUrl: string | undefined,
  sgmContactsUrl: string | undefined,
  headers: { [x: string]: string },
  userEmail: string,
  ssoV2: boolean,
  useDefaultHeaders = true,
  useImperso = true
): ServiceParameters => {
  const { fetch, environment, bus } = widgetConfiguration;
  const icServices = {
    soa: soaUrl || getSoaUrl(environment as Env),
    contactDb: sgmContactsUrl || getContactDbUrl(environment as Env),
    privateServices: getPrivateServiceUrl(environment as Env),
    maestro: getPrivateServiceUrl(environment as Env),
  };
  return {
    bus,
    icServices,
    headers:
      !isEmpty(userEmail) && useImperso
        ? { ...headers, impersonatingAs: userEmail }
        : headers,
    fetch,
    userEmail,
    ssoV2,
    useDefaultHeaders,
    environment: widgetConfiguration.environment as Env,
  };
};

export const buildApiIO = (
  {
    fetch,
    headers,
    icServices,
    ssoV2,
    useDefaultHeaders,
    bus,
    environment,
  }: ServiceParameters,
  baseUrl: (id: string) => string,
  usedService: Service = Service.Soa,
  etag: string | undefined = undefined
): ApiIO => ({
  bus,
  baseUrl,
  fetch,
  headers: buildHeaders(headers, usedService, etag),
  serviceUrl: get(icServices, usedService, ""),
  type: "json" as HttpResponseType,
  ssoV2,
  env: environment,
});

const getHeaderByService = (
  usedService: Service,
  headers: { [x: string]: string }
): { [x: string]: string } => {
  if (usedService === Service.ContactDb) {
    return { ...headers, "x-contact-db": "true" };
  } else {
    return { ...headers };
  }
};

const buildHeaders = (
  headers: { [x: string]: string },
  usedService: Service,
  etag: string | undefined
) => {
  const newHeaders = getHeaderByService(usedService, headers);
  return !!etag ? { ...newHeaders, "if-match": etag } : newHeaders;
};

export const criterias: ConditionCriterias[] = [
  {
    condition: (searchBy: SearchType) => searchBy === SearchType.Name,
    value: (terms: string): Criterias => ({ name: terms }),
  },
  {
    condition: (searchBy: SearchType) => searchBy === SearchType.Email,
    value: (terms: string): Criterias => ({ email: terms }),
  },
  {
    condition: (searchBy: SearchType) =>
      searchBy === SearchType.BdrCommercialId,
    value: (terms: string): Criterias => ({ clientComm: terms }),
  },
  {
    condition: (searchBy: SearchType) => searchBy === SearchType.BdrLegalId,
    value: (terms: string): Criterias => ({ clientLegal: terms }),
  },
  {
    condition: (searchBy: SearchType) => searchBy === SearchType.RctId,
    value: (terms: string): Criterias => ({ clientRct: terms }),
  },
];
