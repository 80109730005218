const EmptyValueMessage = {
  EmptyJobType: "Job type is mandatory",
  EmptyJobTitle: "Job title is mandatory",
  EmptyAddress: "Address is mandatory",
  EmptyPhone: "Phone is mandatory",
  EmptyPhoneType: "Phone type is mandatory",
  EmptyEmail: "Email is mandatory",
  EmptyCivility: "Civility is mandatory",
  EmptyTitle: "Title is mandatory",
  EmptyFirstname: "Firstname is mandatory",
  EmptyLastname: "Lastname is mandatory",
  EmptyName: "Name is mandatory",
  EmptyAccount: "Account is mandatory",
  EmptyCity: "City is mandatory",
  EmptyZipcode: "Post code is mandatory",
  EmptyCountry: "Country is mandatory",
};

const BadFormatMessage = {
  BadFormatEmail: "Wrong email format",
  BadFormatPhone: "Wrong phone format for",
};

const ConflictMessage = {
  ConflictDomainName: "The email doesn't match the selected account",
  ConflictEmail: "This email address already exits",
};

export const ErrorMessage = {
  ...EmptyValueMessage,
  ...BadFormatMessage,
  ...ConflictMessage,
  BadTypeEmail: "Wrong email type",
  Duplicate: "This input has a duplicate",
};
