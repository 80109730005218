/**
 * @deprecated use logic in newTagPicker folder!
 */
import { Component, h } from "preact";
import {
  ListItemAutocomplete,
  ListItemAutoCompleteStateProps,
} from "Sgb4/ListItemAutocomplete/ListItemAutocomplete";
import {
  InputTagList,
  InputTagListStateProps,
} from "Sgb4/InputTagList/InputTagList";
import {
  onRemove,
  onSelect,
  initState,
  onSearch,
  onClear,
  onItemsLoaded,
} from "./state.logic";
import { LabelValue } from "Sgb4/common/Components/Select/Select";
import { isEqual, isEmpty } from "lodash";
import { ErrorProps } from "Sgb4/DeprecatedTagPicker/common/typings";
import { onInput, onFocus, onBlur } from "../common/state.logic";
import { TagPickerDispatchProps, TagPickerStateProps } from "../common/typings";

export enum SearchType {
  Name = "0",
  Email = "1",
  BdrCommercialId = "2",
  BdrLegalId = "3",
  RctId = "4",
}

export interface StateProps<TItem>
  extends TagPickerStateProps<TItem>,
    ErrorProps {
  readonlyItems: TItem[];
  rowMapper: (item: TItem) => JSX.Element;
  renderHeader: () => JSX.Element;
  selectOptions?: Array<LabelValue<SearchType>>;
}

export interface DispatchProps<TItem> extends TagPickerDispatchProps<TItem> {
  onSelectChange?: (optionValue: SearchType) => void;
  onTagsReady: (items: TItem[]) => void;
}

export type TagPickerProps<TItem> = StateProps<TItem> & DispatchProps<TItem>;

export interface TagPickerState<TItem> {
  terms: string;
  tagList: InputTagListStateProps<TItem>;
  autocomplete: ListItemAutoCompleteStateProps<TItem>;
}

export class TagPicker<TItem extends { id: string }> extends Component<
  TagPickerProps<TItem>,
  TagPickerState<TItem>
> {
  constructor(props: TagPickerProps<TItem>) {
    super(props);
    this.state = initState(this.props);
    this.onInput = this.onInput.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClear = this.onClear.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  public componentDidMount() {
    document.addEventListener("click", this.onClick, false);
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.onClick);
  }

  public componentWillReceiveProps(nextProps: TagPickerProps<TItem>) {
    const { items, selectedItems, readonlyItems } = this.props;

    if (items !== nextProps.items) {
      this.setState((state: TagPickerState<TItem>) =>
        onSearch(state, nextProps.items)
      );
    }
    if (
      !isEqual(selectedItems, nextProps.selectedItems) &&
      !isEmpty(nextProps.selectedItems)
    ) {
      this.setState(
        (state: TagPickerState<TItem>, props: TagPickerProps<TItem>) =>
          onItemsLoaded(state, props, nextProps.selectedItems, false),
        () => this.props.onTagsReady(this.state.tagList.tags)
      );
    }

    if (
      !isEqual(readonlyItems, nextProps.readonlyItems) &&
      !isEmpty(nextProps.readonlyItems)
    ) {
      this.setState(
        (state: TagPickerState<TItem>, props: TagPickerProps<TItem>) =>
          onItemsLoaded(state, props, nextProps.readonlyItems, true),
        () => this.props.onTagsReady(this.state.tagList.tags)
      );
    }
  }

  public render(
    {
      maxItemNameLength,
      itemNameMapper,
      placeholder,
      selectOptions,
      onSelectChange,
      renderHeader,
      errorMessage,
      inError,
      headerHeight,
    }: TagPickerProps<TItem>,
    { tagList, autocomplete, terms }: TagPickerState<TItem>
  ) {
    const { disabled, tags, state } = tagList;
    const { isVisible, items, status, reason, exclude } = autocomplete;

    return (
      <div>
        <InputTagList
          displayClearButton={!isEmpty(tags)}
          selectOptions={selectOptions}
          maxItemNameLength={maxItemNameLength}
          onClear={this.onClear}
          onInput={this.onInput}
          onFocus={this.onFocus}
          onRemoveTag={this.onRemove}
          disabled={disabled}
          placeholder={placeholder}
          tags={tags}
          state={state}
          status={status}
          terms={terms}
          mapper={itemNameMapper}
          onSelectChange={onSelectChange}
          inError={inError}
          errorMessage={errorMessage}
        />

        <ListItemAutocomplete
          isVisible={isVisible}
          exclude={exclude}
          items={items}
          reason={reason}
          status={status}
          renderRow={this.renderRow}
          renderHeader={renderHeader}
          headerHeight={headerHeight}
        />
      </div>
    );
  }

  private onInput(terms: string) {
    this.setState(
      (state: TagPickerState<TItem>) => onInput(state, terms),
      () => this.props.onTermsChange(this.state.terms)
    );
  }

  private onSelect(item: TItem) {
    this.setState(
      (state: TagPickerState<TItem>, p: TagPickerProps<TItem>) =>
        onSelect(state, p, item),
      () => this.props.onTagsChange(this.state.tagList.tags)
    );
  }

  private onFocus() {
    this.setState((state: TagPickerState<TItem>) => onFocus(state));
  }

  private onRemove(item: TItem) {
    this.setState(
      (state: TagPickerState<TItem>, props: TagPickerProps<TItem>) =>
        onRemove(state, props, item),
      () => this.props.onTagsChange(this.state.tagList.tags)
    );
  }

  private onClear() {
    this.setState(
      (state: TagPickerState<TItem>) => onClear(state),
      () => this.props.onTagsChange(this.state.tagList.tags)
    );
  }

  private renderRow(item: TItem) {
    return (
      <div class="list-group-item" onClick={() => this.onSelect(item)}>
        {this.props.rowMapper(item)}
      </div>
    );
  }

  private onClick(e: any) {
    if (this.base && this.base.contains(e.target)) {
      return false;
    }
    if (e.target.nodeName === this.props.hostName) {
      return false;
    }
    this.setState((state: TagPickerState<TItem>) => ({
      ...onBlur(state),
      terms: "",
      autocomplete: { ...this.state.autocomplete, items: [] },
    }));
    return true;
  }
}
