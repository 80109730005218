import { h, Component } from "preact";
import { isEmpty, get } from "lodash";

export interface State {
  height: number;
  offset: number | undefined;
}

export interface Props {
  sync: boolean;
  data: any;
  rowHeight: number;
  renderRow: Function;
  renderHeader?: Function;
  headerHeight?: number;
  overscanCount?: number;
  class: string;
}

export class VirtualList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  public componentDidUpdate() {
    this.resize();
  }

  public componentDidMount() {
    this.resize();
    addEventListener("resize", this.resize);
  }

  public componentWillUnmount() {
    removeEventListener("resize", this.resize);
  }

  public render(
    {
      data,
      rowHeight,
      renderRow,
      renderHeader,
      overscanCount = 10,
      sync,
      ...props
    }: Props,
    { offset = 0, height = 0 }: State
  ) {
    // first visible row index
    // tslint:disable-next-line:no-bitwise
    let start = (offset / rowHeight) | 0;

    // actual number of visible rows (without overscan)
    // tslint:disable-next-line:no-bitwise
    let visibleRowCount = (height / rowHeight) | 0;

    // Overscan: render blocks of rows modulo an overscan row count
    // This dramatically reduces DOM writes during scrolling
    if (overscanCount) {
      start = Math.max(0, start - (start % overscanCount));
      visibleRowCount += overscanCount;
    }

    // last visible + overscan row index
    const end = start + 1 + visibleRowCount;

    // data slice currently in viewport plus overscan items
    const selection = data.slice(start, end);
    return (
      <div
        class="position-absolute bg-white w-100"
        onScroll={this.handleScroll}
        style={{
          maxHeight: rowHeight * overscanCount,
          overflow: "auto",
          cursor: "default",
          zIndex: 2000,
        }}
        {...props}
      >
        {!isEmpty(data) ? (
          <div class="position-relative w-100" style={this.getInnerStyle()}>
            <div
              class="position-absolute w-100 h-100"
              style={this.getContentStyle(start)}
            >
              {renderHeader ? renderHeader() : null}
              {selection.map(renderRow)}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  private getInnerStyle = () => {
    const { data, rowHeight, headerHeight } = this.props;
    return {
      overflow: "hidden",
      minHeight: "100%",
      height: (headerHeight || 0) + data.length * rowHeight,
    };
  };

  private getContentStyle = (start: number) => {
    const { rowHeight } = this.props;
    return {
      overflow: "visible",
      left: 0,
      top: start * rowHeight,
    };
  };

  private resize = () => {
    if (this.state.height !== get(this.base, "offsetHeight")) {
      this.setState({ height: get(this.base, "offsetHeight") as any });
    }
  };

  private handleScroll = () => {
    this.setState({ offset: get(this.base, "scrollTop") });
    if (this.props.sync) {
      this.forceUpdate();
    }
  };
}
