import { h, FunctionalComponent } from "preact";
import { WithGlobalCss, IWidgetConfigurationProps } from "@sgwt-widget/core";
import { IProps } from "@sgwt-widget/core/lib/components/WithCss";
import { Props as WithSGB4Props, WithSGB4 } from "../WithSGB4/WithSGB4";

export interface ForceSGB4Props {
  forceSgb4?: boolean;
}
export type WithGlobalCssProps = IProps & IWidgetConfigurationProps;
export type WithCssProps = ForceSGB4Props &
  (WithGlobalCssProps | WithSGB4Props);

const WithCss: FunctionalComponent<WithCssProps> = ({
  forceSgb4 = true,
  ...rest
}) => {
  const Css = forceSgb4 ? WithSGB4 : WithGlobalCss;
  return <Css {...rest} />;
};

export default WithCss;
