import { ContactFormValidation } from "ic-contact-editor/component/validator";
import { InputContact } from "ic-contact-editor/types";

export const emptyForm: InputContact = {
  account: "",
  addresses: [],
  civility: "",
  emails: [],
  firstname: "",
  jobtitle: "",
  jobtype: "",
  lastname: "",
  linkedInUrl: "",
  mainEmail: "",
  mandates: [],
  phones: [],
};

export const accountPickerInit = {
  capacity: 1,
  displaySearchBy: false,
  filter: {},
  headers: {},
  onChange: () => {
    return;
  },
  placeholder: "Search a account",
  readonlyIds: [],
  selectedIds: [],
  selectedSearchBy: "",
  soaUrl: "",
  state: "empty",
  userEmail: "",
};

export const validForm: ContactFormValidation = {
  account: true,
  addresses: true,
  civility: true,
  emailsErrors: [],
  firstname: true,
  isValid: true,
  jobtitle: true,
  jobtype: true,
  lastname: true,
  linkedInUrl: true,
  mainEmail: "",
  mandateErrors: [],
  mandates: true,
  phones: true,
  serverError: "",
};

export const ValidationMessageError = {
  MAIN_MAIL_FORMAT:
    "Please enter a valid professional e-mail address." +
    ' For example: "name@company.com" and without "bloomberg.net" domain name',
  MAIN_MAIL_MANDATORY: "Please enter a professional e-mail address.",
  OPTIONAL_EMAIL_DUPLICATE_VALUES:
    "Please enter another secondary e-mail address. Contact cannot have duplicate " +
    "e-mail addresses.",
  OPTIONAL_EMAIL_FORMAT:
    "Please enter a valid secondary e-mail address." +
    ' For example: "name@company.com"',
  OPTIONAL_EMAIL_MANDATORY_TYPE: "Please choose a secondary e-mail type.",
  PHONE_FORMAT:
    "Please enter a valid phone number that ends with a digit and contains only digits and + ( ) #",
};
