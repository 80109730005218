import { ApiErrorCode } from "ic-contact-editor/api/apiErrors";
import { ErrorMessage } from "ic-sgb4-contact-creator/logic/formValidation/validationMessages";
import { ContactException } from "ic-sgb4-contact-creator/logic/ContactException";
import { ContactCreatorState } from "ic-sgb4-contact-creator/ContactCreator";
import { LightContactCreatorState } from "ic-sgb4-light-contact-creator/LightContactCreator";
import {
  ContactInformation,
  LightContactInformation,
} from "ic-sgb4-light-contact-creator/typings";
import { get } from "lodash";

export const validateConflict = (
  contactInfo: ContactInformation | LightContactInformation,
  exception: ContactException
): Partial<ContactCreatorState | LightContactCreatorState> => {
  const contactInformation = get(contactInfo, "department", false)
    ? getContactInformation(contactInfo as ContactInformation, exception)
    : getContactInformationLight(
        contactInfo as LightContactInformation,
        exception
      );
  return {
    conflict: isConflictException(exception.type)
      ? {
          exceptionMetadata: exception.meta,
          conflictType: exception.type,
          displayDuplicateModal: true,
        }
      : {},
    errorMessage: isConflictException(exception.type)
      ? undefined
      : { message: exception.message, type: "danger" },
    contactInformation,
  };
};

export const isConflictException = (exceptionType: number) =>
  [ApiErrorCode.preConditionRequired, ApiErrorCode.conflict].indexOf(
    exceptionType
  ) > -1;

const getContactInformation = (
  contactInfo: ContactInformation,
  exception: ContactException
) =>
  ({
    ...contactInfo,
    errors: {
      account: { inError: undefined },
      civility: { inError: undefined },
      title: { inError: undefined },
      firstname: { inError: undefined },
      lastname: { inError: undefined },
      name: { inError: undefined },
      jobTitle: { inError: undefined },
      jobType: { inError: undefined },
      department: { inError: undefined },
      email:
        exception.type === ApiErrorCode.conflict
          ? { inError: true, errorMessage: ErrorMessage.ConflictEmail }
          : exception.type === ApiErrorCode.preConditionRequired
          ? { inError: true, errorMessage: ErrorMessage.ConflictDomainName }
          : { inError: undefined },
    },
  } as ContactInformation);

const getContactInformationLight = (
  contactInfo: LightContactInformation,
  exception: ContactException
) =>
  ({
    ...contactInfo,
    errors: {
      account: { inError: undefined },
      civility: { inError: undefined },
      firstname: { inError: undefined },
      lastname: { inError: undefined },
      jobTitle: { inError: undefined },
      email:
        exception.type === ApiErrorCode.conflict
          ? { inError: true, errorMessage: ErrorMessage.ConflictEmail }
          : exception.type === ApiErrorCode.preConditionRequired
          ? { inError: true, errorMessage: ErrorMessage.ConflictDomainName }
          : { inError: undefined },
    },
  } as LightContactInformation);
