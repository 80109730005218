import { ApiResponse, HttpResponseType } from "common/api/types";
import { EmailTypeNames } from "common/constants";
import { AddressDto, ContactDto, EmailDto } from "common/types/contact";
import { ApiErrorCode } from "ic-contact-editor/api/apiErrors";
import {
  ContactDuplication,
  ContactsDuplications,
  InputContact,
  UpdateResult,
} from "ic-contact-editor/types";
import { get, startsWith } from "lodash";
import { isArray } from "lodash";

export const mapToContactDuplication = ({
  id,
  firstname,
  lastname,
  clientFull,
}: ContactDto): ContactDuplication => ({
  accountLevelName: get(clientFull, "internalId.level", "") as string,
  accountName: get(clientFull, "name", "") as string,
  fullName: `${firstname} ${lastname}`,
  id: id!,
});

export const httpResponseforCreationOrUpdate = (
  result: ApiResponse,
  type: HttpResponseType
): Promise<UpdateResult> => {
  const ssoExceptionRaised = isSsoException(result);
  if (
    !result.ok &&
    result.status !== ApiErrorCode.conflict &&
    result.status !== ApiErrorCode.preConditionRequired &&
    !ssoExceptionRaised
  ) {
    throw new Error(`${result.status}`);
  }
  const status = result.status;
  const etag = result.headers.get("etag");
  if (ssoExceptionRaised) {
    return Promise.resolve({ changeSsoAccount: true, etag });
  }
  const responsePromise = result[type]() as Promise<any>;
  return result.ok
    ? responsePromise.then((contact): UpdateResult => ({ contact }))
    : responsePromise.then(
        (contacts: ContactDto[] | ContactDto): UpdateResult => {
          const duplicatedContacts = isArray(contacts) ? contacts : [contacts];
          const duplications = duplicatedContacts.map(mapToContactDuplication);
          return {
            duplications: {
              byMail: status === ApiErrorCode.conflict ? duplications : [],
              byName:
                status === ApiErrorCode.preConditionRequired
                  ? duplications
                  : [],
            },
            etag,
          };
        }
      );
};

export const httpResponseToDuplicationsMapper = (
  result: ApiResponse,
  type: HttpResponseType
) => {
  if (
    !result.ok &&
    result.status !== ApiErrorCode.conflict &&
    result.status !== ApiErrorCode.preConditionRequired
  ) {
    throw new Error(`${result.status}`);
  }
  const status = result.status;
  const responsePromise = result.ok
    ? new Promise(resolve => resolve([]))
    : (result[type]() as Promise<any>);
  return responsePromise.then(
    (contacts: ContactDto[]): ContactsDuplications => {
      const duplications = contacts.map(mapToContactDuplication);
      return {
        byMail: status === ApiErrorCode.conflict ? duplications : [],
        byName:
          status === ApiErrorCode.preConditionRequired ? duplications : [],
      };
    }
  );
};

export const itemToContactDto = (item: InputContact): ContactDto => {
  const addresses = item.addresses.map<AddressDto>(address => ({
    ...address,
    country: address.countryIso3,
  }));

  return {
    ...(item.id ? { id: item.id } : {}),
    addresses,
    civility: {
      ...(item.title ? { title: item.title } : {}),
      gender: item.civility,
      jobTitle: item.jobtitle,
      jobType: item.jobtype,
    },
    clientFull: { internalId: { id: item.account } },
    emails: [
      { email: item.mainEmail, type: EmailTypeNames.Professional },
      ...item.emails,
    ],
    firstname: item.firstname,
    lastname: item.lastname,
    visibility: {
      visibility: item.visibility || "Public",
    },
    ...(item.department ? { department: item.department } : {}),
    ...(item.linkedInUrl ? { linkedin: item.linkedInUrl } : {}),
    phones: item.phones,
  };
};

export const contactDtoToInput = (contact: ContactDto): InputContact => {
  const [mainEmail, ...emails] = contact.emails || ([] as EmailDto[]);
  return {
    account: (contact.clientFull || { internalId: { id: "" } }).internalId!.id!,
    addresses: contact.addresses || [],
    civility: (contact.civility || { gender: "" }).gender,
    emails,
    firstname: contact.firstname || "",
    id: contact.id,
    jobtitle: (contact.civility || { jobTitle: "" }).jobTitle,
    jobtype: (contact.civility || { jobType: "" }).jobType,
    lastname: contact.lastname || "",
    linkedInUrl: contact.linkedin || "",
    mainEmail: mainEmail ? mainEmail.email : "",
    phones: contact.phones || [],
    title: (contact.civility || { title: "" }).title,
    ...(contact.visibility && contact.visibility.visibility
      ? { visibility: contact.visibility.visibility }
      : {}),
    ...(contact.department ? { department: contact.department } : {}),
  };
};

export const isSsoException = (exception: ApiResponse) =>
  exception.status === ApiErrorCode.preConditionFailed &&
  startsWith(
    exception.statusText,
    "This contact has an SSO account using this email."
  );
