import { h } from "preact";
import { Highlight } from "@dcc-cli/ic-shared-components";
import { split } from "lodash";

export enum HighlightType {
  Red,
  Bold,
  Blue,
}

export const highlightInBold = (terms: string, input: string) =>
  highlight(terms, input, HighlightType.Bold);

export const highlight = (
  terms: string,
  input: string,
  type = HighlightType.Blue
): JSX.Element => {
  const spittedTerms = split(terms, " ");
  return (
    <span>
      {type === HighlightType.Red && (
        <Highlight
          term={spittedTerms}
          text={input}
          highlightClassName="text-red"
        />
      )}
      {type === HighlightType.Blue && (
        <Highlight
          term={spittedTerms}
          text={input}
          highlightClassName="text-info"
        />
      )}
      {type === HighlightType.Bold && (
        <Highlight
          term={spittedTerms}
          text={input}
          highlightClassName="font-weight-medium"
        />
      )}
    </span>
  );
};
