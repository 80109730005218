import { AddressTypes, EmailTypeNames } from "common/constants";
import { ClientDto, CountryDto } from "common/types/account";
import { AddressDto, ContactDto, PhoneDto } from "common/types/contact";
import { replaceInArray } from "common/utils";
import { Action, itemList } from "ic-contact-editor/action/types";
import { contactDtoToInput } from "ic-contact-editor/api/mapper";
import { ReducerIO, State } from "ic-contact-editor/reducer/types";
import {
  ContactsDuplications,
  InputContact,
  Inputs,
} from "ic-contact-editor/types";

import { IO } from "ic-account-picker/types";
import {
  accountPickerInit,
  emptyForm,
  validForm,
} from "ic-contact-editor/constants/constants";

import { ContactFormValidation } from "ic-contact-editor/component/validator";

import { InputMandate } from "ic-mandate-creator/component/MandateCreator/types";

import { EmailDto } from "common/types/contact";
import { PhoneTypes } from "ic-contact-editor/constants/presets";
import { difference, find, get, isEmpty } from "lodash";

import { formatPhone } from "ic-contact-editor/api/phoneApi";
import { CountryCode } from "libphonenumber-js";

export const getCountryIso2 = (
  country: CountryDto | undefined,
  addresses: AddressDto[] = [],
  countryReferencial: CountryDto[]
): CountryCode | undefined => {
  const accountCountryIso2 = findCountryIso2(
    country ? country.iso3 : undefined,
    countryReferencial
  );
  const mainAddressIso3 = !isEmpty(addresses)
    ? addresses[0].countryIso3
    : undefined;
  const mainAddressIso2 = findCountryIso2(mainAddressIso3, countryReferencial);

  return (mainAddressIso2 || accountCountryIso2) as CountryCode | undefined;
};

export const findCountryIso2 = (
  inputIso3: string | undefined,
  countryReferencial: CountryDto[]
) => {
  const accountCountry = inputIso3
    ? find(countryReferencial, ({ iso3 }) => iso3 === inputIso3)
    : undefined;
  return accountCountry ? accountCountry.iso2 : undefined;
};

export const getReducer = (init: State) => (
  state = init,
  action: Action
): State => {
  switch (action.type) {
    case "ON_CONTACT_START":
      return onStart(state, action.inputs, action.countries);
    case "ON_CONTACT_CHECK":
      return onCheck(state, action.duplications);
    case "ON_CONTACT_CREATE":
      return onCreate(state, action.contact, action.duplications, action.etag);
    case "ON_CONTACT_UPDATE":
      return onUpdate(
        state,
        action.contact,
        action.duplications,
        action.etag,
        action.changeSsoAccount
      );
    case "ON_CONTACT_LOADING_PROGRESS":
      return onLoading(state);
    case "ON_CONTACT_EDIT":
      return onEdit(state, action.contact);
    case "ON_CONTACT_UPDATE_ACCOUNT":
      return onUpdateAccount(state, action.account);
    case "ON_CONTACT_FORM_ERROR":
      return onFormError(state, action.validation);
    case "ON_CONTACT_MANDATE_ERRORS":
      return onMandateErrors(state, action.errors);
    case "ON_CONTACT_MANDATES_CREATED":
      return onCreateMandates(state, action.ids);
    case "ON_CONTACT_ADD_ITEM":
      return onAddItem(state, action.item);
    case "ON_CONTACT_REMOVE_ITEM":
      return onRemoveItem(state, action.item, action.index);
    case "ON_CONTACT_UPDATE_MANDATES":
      return onUpdateMandates(state, action.mandates);
    case "ON_CONTACT_CHANGED":
      return onContactChanged(state);
    case "ON_CONTACT_EMAILS_CHANGED":
      return onSecondaryEmailChanged(state, action.index, action.email);
    case "ON_CONTACT_ADDRESS_CHANGED":
      return onAddressChanged(state, action.index, action.address);
    case "ON_CONTACT_PHONE_CHANGED":
      return onPhoneChanged(state, action.index, action.phone);
    case "ON_ERROR":
      return onServerError(state, action.reason);
    default:
      return state;
  }
};

export function initState(dependencies: ReducerIO): State {
  const accountPicker: IO<ClientDto> = {
    ...accountPickerInit,
    fetch: dependencies.fetch,
    soaUrl: dependencies.soaUrl,
    ssoV2: dependencies.ssoV2,
    state: "empty",
    bus: dependencies.bus,
    env: dependencies.env,
  };
  return {
    accountPicker,
    changeSsoAccount: false,
    contact: {},
    contactSaved: false,
    countries: [],
    duplications: { byMail: [], byName: [] },
    etag: undefined,
    inputContact: {
      ...emptyForm,
      ...(dependencies.accountId ? { account: dependencies.accountId } : {}),
      mandates: [],
    },
    isEditDuplicate: false,
    mandatesSaved: false,
    mode: "creation",
    save: false,
    status: "init",
    step: "check",
    validation: validForm,
    ssoV2: dependencies.ssoV2,
  };
}

export function reinitState(state: State, dependencies: ReducerIO): State {
  const inputContact = {
    ...state.inputContact,
    ...(dependencies.accountId ? { account: dependencies.accountId } : {}),
  };
  return {
    ...state,
    contact: {},
    contactSaved: false,
    inputContact,
    mandatesSaved: false,
    save: false,
    status: "init",
  };
}

function onUpdateAccount(state: State, account?: ClientDto): State {
  const id = account ? account.id : "";
  const country = account ? account.country : undefined;
  const inputContact = {
    ...state.inputContact,
    account: id,
    country,
    phones: formatPhonesNumber(
      state.inputContact.phones,
      getCountryIso2(
        state.inputContact.country,
        state.inputContact.addresses,
        state.countries
      ),
      getCountryIso2(country, state.inputContact.addresses, state.countries)
    ),
  } as InputContact;

  return {
    ...state,
    contactSaved: false,
    inputContact,
    mandatesSaved: false,
    save: false,
    status: "init",
  };
}

function onLoading(state: State): State {
  return {
    ...state,
    contactSaved: false,
    mandatesSaved: false,
    save: false,
    status: "loading",
  };
}

function onCheck(state: State, duplications: ContactsDuplications): State {
  return {
    ...state,
    contactSaved: false,
    mandatesSaved: false,
    validation: validForm,
    ...{ duplications },
    save: false,
    status: "init",
    step: "form",
  };
}

function onCreate(
  state: State,
  contact?: ContactDto,
  duplications?: ContactsDuplications,
  etag?: string
): State {
  const mandates = state.inputContact.mandates;
  return {
    ...state,
    validation: validForm,
    ...(contact
      ? {
          contact,
          contactSaved: true,
          duplications: { byMail: [], byName: [] },
          inputContact: {
            country: state.inputContact.country,
            ...contactDtoToInput(contact),
            id: contact.id,
            mandates,
          },
          mandatesSaved: false,
          mode: "edition",
          save: true,
          status: "loading", // waiting for save mandates.
        }
      : {
          contactSaved: false,
          duplications: duplications || { byMail: [], byName: [] },
          etag,
          mandatesSaved: false,
          save: false,
          status: "init",
        }),
  };
}

function onUpdate(
  state: State,
  contact: ContactDto,
  duplications: ContactsDuplications,
  etag: string | undefined,
  changeSsoAccount: boolean | undefined
): State {
  return {
    ...state,
    changeSsoAccount,
    contactSaved: true,
    etag,
    mandatesSaved: false,
    save: true,
    status: "loading", // waiting for save mandates.
    validation: validForm,
    ...(contact
      ? { contact, duplications: { byMail: [], byName: [] } }
      : { duplications, isEditDuplicate: false }),
  };
}

function onCreateMandates(state: State, _: string[]): State {
  return {
    ...state,
    mandatesSaved: true,
    save: false,
    status: "init",
  };
}

function onEdit(state: State, contact: ContactDto): State {
  return {
    ...state,
    contactSaved: false,
    duplications: { byMail: [], byName: [] },
    inputContact: {
      country: state.inputContact.country,
      ...contactDtoToInput(contact),
    },
    isEditDuplicate: true,
    mandatesSaved: false,
    mode: "edition",
    save: false,
    status: "init",
    validation: validForm,
  };
}

function onStart(state: State, inputs: Inputs, countries: CountryDto[]): State {
  const { account, contact } = inputs;
  const country = get(account, "country");
  return {
    ...state,
    ...(contact ? { mode: "edition", step: "form" } : {}),
    contactSaved: false,
    countries: countries || [],
    inputContact: contact
      ? { ...contactDtoToInput(contact), country }
      : {
          ...state.inputContact,
          ...(account ? { account: account.id as string } : {}),
          country,
        },
    mandatesSaved: false,
    save: false,
    status: "init",
    validation: validForm,
  };
}

function onServerError(state: State, reason: string): State {
  const validation = {
    ...state.validation,
    isValid: false,
    serverError: reason,
  } as ContactFormValidation;
  return {
    ...state,
    contactSaved: false,
    mandatesSaved: false,
    save: false,
    status: "init",
    validation,
  };
}

function onFormError(state: State, validation: ContactFormValidation): State {
  return {
    ...state,
    contactSaved: false,
    mandatesSaved: false,
    save: false,
    status: "init",
    validation,
  };
}

export function onMandateErrors(state: State, mandateErrors: string[]): State {
  const hasMandateErrors = mandateErrors.length === 0;
  const isValid = state.validation.isValid && hasMandateErrors;
  const validation = {
    ...state.validation,
    mandateErrors,
    mandates: hasMandateErrors,
    isValid,
  };
  return {
    ...state,
    contactSaved: false,
    mandatesSaved: false,
    save: false,
    status: "init",
    validation,
  };
}

function getDefaultValue(initialList: string[], currentList: string[]): string {
  const availableList = difference<string>(initialList, currentList);
  return availableList.length > 0 ? availableList[0] : initialList[0];
}

function onAddItem(state: State, item: itemList): State {
  const input: InputContact = { ...state.inputContact };

  const addresses =
    item === "addresses"
      ? [
          ...input.addresses,
          {
            countryIso3: get(input, "country.iso3"),
          } as AddressDto,
        ]
      : input.addresses;
  const phones =
    item === "phones"
      ? [
          ...input.phones,
          {
            phone: "",
            countryCodeIso2: "",
            type: getDefaultValue(
              PhoneTypes,
              input.phones.map(p => p.type)
            ),
          },
        ]
      : input.phones;
  const emails =
    item === "emails"
      ? [...input.emails, { email: "", type: EmailTypeNames.Professional }]
      : input.emails;

  return {
    ...state,
    contactSaved: false,
    inputContact: { ...input, emails, phones, addresses },
    mandatesSaved: false,
    save: false,
    status: "init",
  };
}

function onRemoveItem(state: State, item: itemList, index: number): State {
  const input: InputContact = { ...state.inputContact };
  input[item].splice(index, 1);
  const phones = formatPhonesNumber(
    state.inputContact.phones,
    getCountryIso2(
      state.inputContact.country,
      state.inputContact.addresses,
      state.countries
    ),
    getCountryIso2(
      state.inputContact.country,
      state.inputContact.addresses,
      state.countries
    )
  );
  return {
    ...state,
    contactSaved: false,
    inputContact: { ...input, phones },
    mandatesSaved: false,
    save: false,
    status: "init",
  };
}

function onUpdateMandates(state: State, mandates: InputMandate[]): State {
  return {
    ...state,
    contactSaved: false,
    inputContact: {
      ...state.inputContact,
      mandates,
    },
    mandatesSaved: false,
    save: false,
    status: "init",
  };
}

function onContactChanged(state: State): State {
  return {
    ...state,
    contactSaved: false,
    duplications: { byMail: [], byName: [] },
    etag: undefined,
    isEditDuplicate: false,
    mandatesSaved: false,
    save: false,
    status: "init",
  };
}

function onSecondaryEmailChanged(
  state: State,
  index: number,
  email: EmailDto
): State {
  const emails = replaceInArray(state.inputContact.emails, index, email);
  return {
    ...state,
    contactSaved: false,
    inputContact: {
      ...state.inputContact,
      emails,
    },
    mandatesSaved: false,
    save: false,
    status: "init",
  };
}

function onAddressChanged(
  state: State,
  index: number,
  address: AddressDto
): State {
  const addresses = replaceInArray(
    state.inputContact.addresses,
    index,
    address
  );
  const phones = formatPhonesNumber(
    state.inputContact.phones,
    getCountryIso2(
      state.inputContact.country,
      state.inputContact.addresses,
      state.countries
    ),
    getCountryIso2(state.inputContact.country, addresses, state.countries)
  );

  return {
    ...state,
    contactSaved: false,
    inputContact: {
      ...state.inputContact,
      addresses,
      phones,
    },
    mandatesSaved: false,
    save: false,
    status: "init",
  };
}

function onPhoneChanged(state: State, index: number, phone: PhoneDto): State {
  const countryIso2 = getCountryIso2(
    state.inputContact.country,
    state.inputContact.addresses,
    state.countries
  );
  const phones = formatPhonesNumber(
    replaceInArray(state.inputContact.phones, index, phone),
    countryIso2,
    countryIso2
  );

  return {
    ...state,
    contactSaved: false,
    inputContact: {
      ...state.inputContact,
      phones,
    },
    mandatesSaved: false,
    save: false,
    status: "init",
  };
}

const formatPhonesNumber = (
  phones: PhoneDto[],
  currentCountryIso2: CountryCode | undefined,
  targetCountryIso2: CountryCode | undefined
) => {
  return phones.map((phoneDto: PhoneDto) => ({
    ...phoneDto,
    phone: formatPhone(phoneDto.phone, currentCountryIso2, targetCountryIso2),
  }));
};
