import { lowerCase, trim, upperFirst, endsWith } from "lodash";
import { diacriticsMap } from "Sgb4/common/strings/diacriticsMap.const";

export const capitalizeFirstLetter = (value: string) =>
  upperFirst(lowerCase(value));

export const isStringEqual = <
  T extends string = string,
  V extends string = string
>(
  value?: T,
  other?: V,
  trimSpaces = true,
  insensitive = false
): boolean => {
  if (!value && !other) {
    return true;
  }
  const { value1, value2 } = trimSpaces
    ? { value1: trim(value), value2: trim(other) }
    : { value1: value, value2: other };

  const definedValue1 =
    value1 !== undefined ? value1.toLocaleLowerCase() : undefined;
  const definedValue2 =
    value2 !== undefined ? value2.toLocaleLowerCase() : undefined;

  return insensitive ? value1 === value2 : definedValue1 === definedValue2;
};

export const removeDiacritics = (value: string) => {
  let result = value;
  for (const diacritic of diacriticsMap) {
    result = result.replace(diacritic.letters, diacritic.base);
  }
  return result;
};

/**
 * Remove characters forbidden by IIS (default value of RequestPathInvalidCharacters)
 * @param value to sanitize
 */
export const sanitizeRequestPath = (value: string) =>
  value.replace(/[<>]/g, "");

export const sanitizeSearchTerm = (value: string) =>
  sanitizeRequestPath(removeDiacritics(trim(value || "").toLocaleLowerCase()));

export const compareStringWithoutEmpty = (
  value1: string,
  value2: string
): boolean => {
  if (!value1 || !value2) {
    return true;
  }
  return isStringEqual(value1, value2);
};

export const isContainSubString = (
  value: string,
  other: string,
  trimSpaces = true,
  ignoreCase = true
): boolean => {
  const { value1, value2 } = trimSpaces
    ? { value1: trim(value), value2: trim(other) }
    : { value1: value, value2: other };
  return ignoreCase
    ? value1.toLowerCase().indexOf(value2.toLowerCase()) >= 0
    : value1.indexOf(value2) >= 0;
};

export const isStringEmpty = (value?: string | null): boolean =>
  value === undefined ||
  value === null ||
  trim(value) === "" ||
  trim(value).toLocaleLowerCase() === "null" ||
  trim(value).toLocaleLowerCase() === "undefined";

export const stringComparator = (value?: string, other?: string): number =>
  (isStringEmpty(value) && isStringEmpty(other)) || isStringEqual(value, other)
    ? 0
    : trim(value).toLowerCase() > trim(other).toLowerCase()
    ? 1
    : -1;

export const convertToBoolean = (
  value: string | undefined,
  defaultValue: boolean | undefined = undefined
): boolean | undefined =>
  isStringEqual(value, "true")
    ? true
    : isStringEqual(value, "false")
    ? false
    : defaultValue;

export const pluralize = (word: string, n = 10) =>
  endsWith(word, "s") ? word : `${word}${n > 1 ? "s" : ""}`;
