export class ContactException {
  public type: number;
  public message: string;
  public meta?: any;
  public etag?: string;
  constructor(type: number, message: string, meta?: any, etag?: string) {
    this.type = type;
    this.message = message;
    this.meta = meta;
    this.etag = etag;
  }
}
