import { h } from "preact";
import { IconClose } from "Sgb4/common/Icons/IconClose";
import { truncate, omit } from "lodash";

export interface TagProps<TItem> {
  item: TItem & { isReadonly: boolean };
  maxItemNameLength?: number;
  onRemove: (item: TItem & { isReadonly: boolean }) => void;
  mapper: (item: TItem & { isReadonly: boolean }) => string;
}

export const Tag = ({
  item,
  onRemove,
  mapper,
  maxItemNameLength = 50,
}: any) => (
  <span class="badge sgbs-badge-default mr-1 my-1">
    {!item.isReadonly && (
      <i class="icon" onClick={() => onRemove(item)}>
        <IconClose />
      </i>
    )}
    <span>{truncate(mapper(item), { length: maxItemNameLength })}</span>
  </span>
);
