import {
  buildApiIO,
  CheckDomainNameParameter,
  Service,
  ServiceParameters,
} from "Sgb4/common/api/api.common";
import {
  create,
  getCdbItems,
  getItemsByIds,
  search,
  searchPost,
  update,
} from "common/api";
import { isArray, isEmpty, join, map, uniq } from "lodash";
import { toGetCriteria } from "common/criteria/contact";
import { ContactDto, Properties, SearchCriteria } from "common/types/contact";
import {
  isStringEmpty,
  sanitizeSearchTerm,
} from "Sgb4/common/strings/stringUtils";
import { Env, getContactDbUrl } from "common/api/apiUrls";

import {
  ContactDbReadDto,
  ContactDbWriteDto,
  SearchCriteriaCdb,
} from "Sgb4/common/types/contact.typings";
import { HttpResponseType } from "common/api/types";
import { ContactException } from "ic-sgb4-contact-creator/logic/ContactException";
import {
  ContactsDuplications,
  EmailJustificationDto,
  SuggestionDto,
} from "ic-contact-editor/types";
import { isConflictException } from "ic-sgb4-contact-creator/logic/mappers/mapConflictsToState";

const rootUrl = "2.0/contacts";
const contactRootUrl = "/1.0/sg/contacts";

const baseUrl = (id: string | undefined) => (id ? `${rootUrl}/${id}` : rootUrl);

const contactDbBaseUrl = (id: string) =>
  id ? `${contactRootUrl}/${id}` : `${contactRootUrl}`;

const defaultProperties: Properties[] = [
  "clientFull",
  "mainEmail",
  "scope",
  "civility",
  "mainAddress",
  "portfolio",
  "emails",
  "phones",
  "team",
  "visibility",
];

const ALL_CONTACT_DB_PROPERTIES: string[] = [
  "account",
  "country",
  "civility",
  "jobType",
  "jobTitle",
  "visibility",
  "emails",
  "phones",
  "addresses",
  "socialnetworks",
  "owners",
  "igg",
  "isPepOrSpo",
  "title",
  "isInternal",
  "internalHrTeam",
];

const contactResponseMapper = (
  result: Response & { [type: string]: () => Promise<any> },
  type: HttpResponseType
) => {
  if (result.ok) {
    return new Promise(resolve => resolve(result[type]()));
  }

  return new Promise((_, reject) =>
    result[type]().then(response => {
      const errorBody = isConflictException(result.status)
        ? response
        : undefined;
      const message =
        response && response.UserMessage
          ? response.UserMessage
          : result.statusText;
      reject(
        new ContactException(
          result.status,
          message,
          errorBody,
          result.headers.get("etag") || undefined
        )
      );
    })
  );
};

export const getLegacyContactsById = (
  serviceParams: ServiceParameters,
  ids: string[],
  properties: Properties[] = defaultProperties,
  onlyActive = true
) => {
  return getItemsByIds<ContactDto>(buildApiIO(serviceParams, baseUrl))({
    ...toGetCriteria(uniq(ids), properties),
    onlyActive,
  });
};

export const getContactsById = (
  serviceParams: ServiceParameters,
  ids: string[],
  properties = ALL_CONTACT_DB_PROPERTIES,
  onlyActive = true
): Promise<ContactDbReadDto[]> => {
  return getCdbItems<ContactDbReadDto>(
    buildApiIO(serviceParams, contactDbBaseUrl, Service.ContactDb)
  )({
    ids,
    fields: isEmpty(properties) ? undefined : join(properties, ","),
    onlyActive,
  });
};

export const searchContactLegacyByTerm = (
  term: string,
  serviceParams: ServiceParameters,
  searchCriteria: SearchCriteria,
  pageSize = 500,
  limitBeforeSearch = 2
): Promise<ContactDto[]> => {
  const sanitized = sanitizeSearchTerm(term || "");
  const defaultQueryParam: SearchCriteria = {
    ...searchCriteria,
    search: sanitized,
    properties: defaultProperties.join(","),
    pageSize,
    sort: "smartSort",
  };

  return isStringEmpty(sanitized) || sanitized.length < limitBeforeSearch
    ? Promise.resolve([])
    : search<ContactDto[]>(buildApiIO(serviceParams, baseUrl))(
        defaultQueryParam
      ).then((contacts: any[]) => contacts);
};

export const searchContactByTerm = (
  serviceParams: ServiceParameters,
  term: string,
  searchCriteria: SearchCriteriaCdb,
  limitBeforeSearch = 3,
  pageSize = 500,
  properties = ALL_CONTACT_DB_PROPERTIES
): Promise<ContactDbReadDto[]> => {
  const sanitized = sanitizeSearchTerm(term || "");
  const defaultQueryParam = {
    isInternal: searchCriteria.isInternal,
    onlyActive:
      searchCriteria.onlyActive != null ? searchCriteria.onlyActive : true,
    search: sanitized,
    fields: isEmpty(properties) ? undefined : join(properties, ","),
    pageSize: !pageSize && pageSize <= 0 ? 500 : pageSize,
    sort: "score",
  };

  return isStringEmpty(sanitized) || sanitized.length < limitBeforeSearch
    ? Promise.resolve([])
    : search<ContactDbReadDto[]>(
        buildApiIO(serviceParams, contactDbBaseUrl, Service.ContactDb)
      )(defaultQueryParam).then((response: any) =>
        response && response.contacts ? response.contacts : []
      );
};

export const createContact = (
  environment: Env,
  serviceParams: ServiceParameters,
  contact: ContactDbWriteDto,
  etag: string | undefined = undefined
): Promise<ContactDbWriteDto> => {
  const contactDbUrl = () => getContactDbUrl(environment) + "/1.0/sg/contacts";
  // fix contact
  const data: ContactDbWriteDto = {
    ...contact,
    phones: contact.phones
      ? map(contact.phones, ({ value, type }) => ({ type, value }))
      : [],
  } as ContactDbWriteDto;
  return create<ContactDbWriteDto>(
    buildApiIO(serviceParams, contactDbUrl, Service.ContactDb, etag),
    contactResponseMapper
  )({
    data,
  });
};

export const updateContact = (
  environment: Env,
  contactId: string,
  serviceParams: ServiceParameters,
  contact: ContactDbWriteDto,
  etag: string | undefined = undefined
): Promise<ContactDbWriteDto> => {
  // fix contact
  const data: ContactDbWriteDto = {
    ...contact,
    phones: contact.phones
      ? map(contact.phones, ({ value, type }) => ({ type, value }))
      : [],
  } as ContactDbWriteDto;

  const contactDbUrl = () =>
    `${getContactDbUrl(environment)}/1.0/sg/contacts/${contactId}`;
  return update<ContactDbWriteDto>(
    buildApiIO(serviceParams, contactDbUrl, Service.ContactDb, etag),
    contactResponseMapper
  )({
    data,
  });
};

export const checkContactValidationApi = (
  serviceParams: ServiceParameters,
  body: CheckDomainNameParameter
): Promise<ContactsDuplications> => {
  const apiIO = buildApiIO(
    serviceParams,
    () => "/1.0/sg/contacts/check",
    Service.ContactDb
  );
  return searchPost<ContactsDuplications>(apiIO, contactResponseMapper)(body);
};

export const getEmailJustificationApi = (
  serviceParams: ServiceParameters
): Promise<EmailJustificationDto> => {
  const url = () => "/1.0/sg/contacts/personalemailjustifications";
  return search<EmailJustificationDto>(
    buildApiIO(serviceParams, url, Service.ContactDb),
    contactResponseMapper
  )({});
};

export const getSuggestedDomainApi = (
  contactEmail: string,
  serviceParams: ServiceParameters
): Promise<SuggestionDto> => {
  const url = () => "/1.0/sg/contacts/suggestions";
  return search<SuggestionDto>(
    buildApiIO(serviceParams, url, Service.ContactDb)
  )({ email: contactEmail });
};
