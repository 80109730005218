import { map, find, isEmpty } from "lodash";
import {
  searchContactByTerm,
  getContactsById,
} from "Sgb4/common/api/contacts/contact.api";
import {
  ServiceParameters,
  limitBeforeSearch,
} from "Sgb4/common/api/api.common";
import { mapToReadContact } from "./mapper";
import {
  ContactDbReadDto,
  SearchCriteriaCdb,
} from "../Sgb4/common/types/contact.typings";
import { PickerIdName } from "../Sgb4/common/types/common.typings";

export type ReadContact = ContactDbReadDto &
  PickerIdName & { selected: boolean };

export const fetchContacts = (
  serviceParams: ServiceParameters,
  selectedIds: string[],
  readonlyIds: string[]
): Promise<ReadContact[]> =>
  Promise.all([
    getContactsById(serviceParams, selectedIds),
    getContactsById(serviceParams, readonlyIds),
  ]).then(([contacts, readonlyContacts]) => [
    ...map(contacts, contact => mapToReadContact(contact, false)),
    ...map(readonlyContacts, contact => mapToReadContact(contact, true)),
  ]);

export const fetchContactsByTerm = (
  searchTerm: string,
  {
    serviceParams,
    maxResultCount,
  }: { serviceParams: ServiceParameters; maxResultCount: number },
  searchCriteria: SearchCriteriaCdb,
  selectedContacts: ReadContact[] = []
): Promise<ReadContact[]> =>
  searchContactByTerm(
    serviceParams,
    searchTerm,
    searchCriteria,
    limitBeforeSearch,
    maxResultCount
  ).then((contactsDto: ContactDbReadDto[]) =>
    map(contactsDto, contact => {
      if (isEmpty(selectedContacts)) {
        return mapToReadContact(contact, false);
      }
      const selected =
        find(selectedContacts, ({ id }) => id === contact.id) != null;
      return mapToReadContact(contact, false, selected);
    })
  );
