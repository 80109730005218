import { RenderableProps, h } from "preact";

export interface InvalidFeedbackProps {
  errorMessage?: string;
  errorType?: "danger" | "warning";
}

export const InvalidFeedback = ({
  errorMessage,
  errorType = "danger",
}: RenderableProps<InvalidFeedbackProps>) => (
  <div
    className={errorType === "danger" ? "invalid-feedback" : "warning-feedback"}
    style={{ display: "block" }}
  >
    {errorMessage}
  </div>
);

export const getErrorClass = (inError?: boolean, errorType = "danger") =>
  inError === undefined
    ? ""
    : inError === true
    ? errorType === "danger"
      ? "is-invalid"
      : "is-warning"
    : "";
