import { ReadContact } from "./fetchContacts";
import { ContactDbReadDto } from "../Sgb4/common/types/contact.typings";
import { ContactDto } from "../common/types/contact";
import { get, map } from "lodash";

export const mapToReadContact = (
  contact: ContactDbReadDto,
  readonly: boolean,
  selected = false
): ReadContact => ({
  ...contact,
  readonly,
  selected,
  filtered: false,
  id: contact.id || "",
  name: contact.fullName,
  lastname: contact.name,
});

export const mapToReadContactFromLegacy = (
  contact: ContactDto,
  readonly = false
): ReadContact =>
  ({
    id: contact.id,
    name: `${contact.firstname} ${contact.lastname}`,
    givenName: contact.firstname,
    lastname: contact.lastname,
    fullName: `${contact.firstname} ${contact.lastname}`,
    addresses: contact.addresses,
    phones: map(contact.phones, phone => ({
      value: phone.phone,
      type: phone.type,
      countryCodeIso2: phone.countryCodeIso2,
      isMainPhone: phone.type === "Business",
    })),
    emails: map(contact.emails, email => ({
      value: email.email,
      type: email.type,
      isMainEmail: email.type === "Professional",
    })),
    country: {
      iso3: get(contact, "mainAddress.countryIso3"),
      name: get(contact, "mainAddress.country"),
    },
    civility: get(contact, "civility.title"),
    jobTitle: get(contact, "civility.jobTitle"),
    jobType: get(contact, "civility.type"),
    title: get(contact, "civility.title"),
    isPepOrSpo: contact.isPeporsPo,
    employeeOf: {
      bdrCommercial: get(contact, "clientFull.bdrCommercialId"),
      bdrLegal: get(contact, "clientFull.bdrLegalId"),
      id: get(contact, "clientFull.internalId.id"),
      name: get(contact, "clientFull.name"),
      type: get(contact, "clientFull.internalId.level"),
    },
    employeeOfAccountId: get(contact, "clientFull.internalId.id"),
    isActive: contact.isActive,
    visibility: get(contact, "visibility.visibility"),
    internalHrTeam: get(contact, "organizationTeam.path"),
    isInternal: get(contact, "scope.isInternal"),
    isIcUser: get(contact, "scope.isIcUser"),
    isInMyPortfolio: get(contact, "portfolio.isInMyPortfolio"),
    isSgGroupContact: get(contact, "scope.isSgGroupContact"),
    owners: contact.owners,
    type: get(contact, "civility.type"),
    mergedTo: contact.mergedTo,
    socialNetworks: [],
    igg: contact.igg,
    ecId: undefined,
    filtered: false,
    selected: false,
    readonly,
  } as ReadContact);
