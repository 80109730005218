import {
  GetCriteria,
  HttpResponseType,
  HttpResultMapper,
} from "common/api/types";
import { Fetch } from "common/types/global";
import { isEmpty, map } from "lodash";
import * as URI from "urijs";
import { getSgConnectToken } from "common/auth/sgConnectAuth";
import { IBus } from "@sg-widgets/platform-api/lib/bus";
import { afpUrlsFormat } from "./afpUrlsFormat";
import { Env } from "common/api/apiUrls";

export interface ApiIO {
  bus: IBus;
  type: HttpResponseType;
  baseUrl: Function;
  fetch: Fetch;
  headers: any;
  serviceUrl: string;
  ssoV2: boolean;
  env: Env;
}

export const paginatedSearch = <T>(
  { type, baseUrl, fetch, headers, serviceUrl, ssoV2, bus, env }: ApiIO,
  mapResult = (_: any) => _,
  resultMapper: HttpResultMapper = httpGetMapper,
  page = 0,
  result = []
) => (criteria: any): Promise<T> => {
  const url = URI(baseUrl())
    .absoluteTo(serviceUrl)
    .query(URI.buildQuery({ ...criteria, page }))
    .toString();

  return httpGet(bus, type, fetch, headers, url, resultMapper, ssoV2, env).then(
    (paginatedResult): any => {
      if (!isEmpty(mapResult(paginatedResult))) {
        result = [...result, ...mapResult(paginatedResult)] as any;
        return paginatedSearch<T>(
          {
            type,
            baseUrl,
            fetch,
            headers,
            serviceUrl,
            ssoV2,
            bus,
            env,
          },
          mapResult,
          resultMapper,
          ++page,
          result
        )(criteria);
      } else {
        return Promise.resolve(result);
      }
    }
  );
};

export const search = <T>(
  { type, baseUrl, fetch, headers, serviceUrl, ssoV2, bus, env }: ApiIO,
  resultMapper: HttpResultMapper = httpGetMapper
) => (criteria: any): Promise<T> => {
  const url = URI(baseUrl())
    .absoluteTo(serviceUrl)
    .query(URI.buildQuery(criteria))
    .toString();
  return httpGet(bus, type, fetch, headers, url, resultMapper, ssoV2, env);
};

export const searchPost = <T>(
  { type, baseUrl, fetch, headers, serviceUrl, ssoV2, bus, env }: ApiIO,
  resultMapper: HttpResultMapper = httpGetMapper
) => (criteria: any): Promise<T> => {
  const url = URI(baseUrl())
    .absoluteTo(serviceUrl)
    .toString();
  return httpPost(
    bus,
    type,
    fetch,
    headers,
    url,
    criteria,
    resultMapper,
    ssoV2,
    env
  );
};

export const getItemsByTypeAndIds = <TItem, TCriteria>(
  { type, baseUrl, fetch, headers, serviceUrl, ssoV2, bus, env }: ApiIO,
  searchBy: string,
  toCriteria: (terms: string) => TCriteria,
  resultMapper: HttpResultMapper = httpGetMapper
) => (criteria: GetCriteria): Promise<TItem[]> => {
  const values = map(criteria.ids, id => {
    const base = baseUrl();
    const url = URI(serviceUrl)
      .segment([base])
      .query(URI.buildQuery(toCriteria(searchBy + ":" + id)))
      .toString();
    return httpGet(bus, type, fetch, headers, url, resultMapper, ssoV2, env);
  });
  return Promise.all<TItem>(values);
};

export const getItemsByIds = <TItem>({
  bus,
  type,
  baseUrl,
  fetch,
  headers,
  serviceUrl,
  ssoV2,
  env,
}: ApiIO) => (criteria: GetCriteria): Promise<TItem[]> => {
  const values = map(criteria.ids, id => {
    const base = baseUrl(id);
    const url = URI(serviceUrl)
      .segment([base])
      .query(URI.buildQuery({ properties: criteria.properties }))
      .toString();
    return httpGet(bus, type, fetch, headers, url, httpGetMapper, ssoV2, env);
  });
  return Promise.all<TItem>(values);
};

export const getCdbItems = <TItem>({
  bus,
  type,
  baseUrl,
  fetch,
  headers,
  serviceUrl,
  ssoV2,
  env,
}: ApiIO) => (criteria: any): Promise<TItem[]> => {
  const values = map(criteria.ids, id => {
    const base = baseUrl(id);
    const url = URI(serviceUrl)
      .segment([base])
      .query(URI.buildQuery({ fields: criteria.fields }))
      .toString();
    return httpGet(bus, type, fetch, headers, url, httpGetMapper, ssoV2, env);
  });
  return Promise.all<TItem>(values);
};

export const create = <T>(
  { type, baseUrl, fetch, headers, serviceUrl, ssoV2, bus, env }: ApiIO,
  resultMapper: HttpResultMapper = httpGetMapper
) => (criteria: any): Promise<T> => {
  const url = URI(baseUrl())
    .absoluteTo(serviceUrl)
    .query(URI.buildQuery({ properties: criteria.properties }))
    .toString();
  return httpPost(
    bus,
    type,
    fetch,
    headers,
    url,
    criteria.data,
    resultMapper,
    ssoV2,
    env
  );
};

export const update = <T>(
  { type, baseUrl, fetch, headers, serviceUrl, ssoV2, bus, env }: ApiIO,
  resultMapper: HttpResultMapper = httpGetMapper
) => (criteria: any): Promise<T> => {
  const url = URI(baseUrl(criteria.data.id))
    .absoluteTo(serviceUrl)
    .query(URI.buildQuery({ properties: criteria.properties }))
    .toString();

  return httpPut(
    bus,
    type,
    fetch,
    headers,
    url,
    criteria.data,
    resultMapper,
    ssoV2,
    env
  );
};

export const remove = <T>(
  { type, baseUrl, fetch, headers, serviceUrl, ssoV2, bus, env }: ApiIO,
  resultMapper: HttpResultMapper = httpGetMapper
) => (criteria: any): Promise<T> => {
  const url = URI(baseUrl(criteria.id))
    .absoluteTo(serviceUrl)
    .toString();
  return httpDelete(bus, type, fetch, headers, url, resultMapper, ssoV2, env);
};

export const httpGetMapper = (result: any, type: HttpResponseType) => {
  if (!result.ok) {
    throw new Error(result.statusText);
  }
  if (result.statusText === "No Content") {
    return Promise.resolve();
  }
  return result[type]() as Promise<any>;
};

export const httpGet = (
  bus: IBus,
  type: HttpResponseType,
  fetch: Fetch,
  headers: any,
  url: string,
  resultMapper: HttpResultMapper,
  ssoV2: boolean,
  env: Env
): Promise<any> => {
  return http(
    bus,
    "GET",
    type,
    fetch,
    headers,
    url,
    undefined,
    resultMapper,
    ssoV2,
    env
  );
};

const httpPost = (
  bus: IBus,
  type: HttpResponseType,
  fetch: Fetch,
  headers: any,
  url: string,
  data: any,
  resultMapper: HttpResultMapper,
  ssoV2: boolean,
  env: Env
): Promise<any> => {
  const postHeaders = {
    ...headers,
    "Content-Type": "application/json",
  };
  return http(
    bus,
    "POST",
    type,
    fetch,
    postHeaders,
    url,
    data,
    resultMapper,
    ssoV2,
    env
  );
};

const httpPut = (
  bus: IBus,
  type: HttpResponseType,
  fetch: Fetch,
  headers: any,
  url: string,
  data: any,
  resultMapper: HttpResultMapper,
  ssoV2: boolean,
  env: Env
): Promise<any> => {
  const putHeaders = {
    ...headers,
    "Content-Type": "application/json",
  };
  return http(
    bus,
    "PUT",
    type,
    fetch,
    putHeaders,
    url,
    data,
    resultMapper,
    ssoV2,
    env
  );
};

const httpDelete = (
  bus: IBus,
  type: HttpResponseType,
  fetch: Fetch,
  headers: any,
  url: string,
  resultMapper: HttpResultMapper,
  ssoV2: boolean,
  env: Env
): Promise<any> => {
  const deleteHeaders = {
    ...headers,
    "Content-Type": "application/json",
  };
  return http(
    bus,
    "DELETE",
    type,
    fetch,
    deleteHeaders,
    url,
    undefined,
    resultMapper,
    ssoV2,
    env
  );
};

const http = (
  bus: IBus,
  method: string,
  type: HttpResponseType,
  fetch: Fetch,
  headers: any,
  url: string,
  data: any,
  resultMapper: HttpResultMapper,
  ssoV2: boolean,
  environment: Env
): Promise<any> => {
  return getSgConnectToken(bus).then(token => {
    const httpHeaders = ssoV2
      ? { ...headers, Authorization: token || null, accessToken: token || null }
      : { ...headers };

    const request: RequestInit = {
      credentials: "include",
      headers: httpHeaders,
      method,
      ...(data ? { body: JSON.stringify(data) } : {}),
    };
    const afpUrl = afpUrlsFormat(url, environment);
    // tslint:disable-next-line:ban
    const response = fetch(afpUrl, request);
    return response.then(r => resultMapper(r, type));
  });
};
