export const soaConfigUrls = {
  production: "https://ic-api.sgmarkets.com",
  iso: "https://ic-api-iso.fr.world.socgen",
  homologation: "https://ic-api-hom.fr.world.socgen",
  development: "https://ic-api-hom.fr.world.socgen",
  local: "/", // webpack proxy
};

export const contactDbConfigUrls = {
  production: "https://contacts-api.sgmarkets.com",
  iso: "https://contacts-api-iso.fr.world.socgen",
  homologation: "https://contacts-api-hom.fr.world.socgen",
  development: "https://contacts-api-hom.fr.world.socgen",
  local: "/", // webpack proxy
};

export const privateServiceConfigUrls = {
  production: "https://ic.sgmarkets.com",
  iso: "https://ic-iso.fr.world.socgen",
  homologation: "https://ic-hom.fr.world.socgen",
  development: "https://ic-hom.fr.world.socgen",
  local: "https://ic-hom.fr.world.socgen",
};

export type Env =
  | "local"
  | "development"
  | "homologation"
  | "iso"
  | "production";

export const getSoaUrl = (env: Env) => soaConfigUrls[env];

export const getContactDbUrl = (env: Env) => contactDbConfigUrls[env];

export const getPrivateServiceUrl = (env: Env) => privateServiceConfigUrls[env];
