import {
  soaConfigUrls,
  contactDbConfigUrls,
  privateServiceConfigUrls,
  Env,
} from "./apiUrls";
import { startsWith } from "lodash";
import urljoin = require("url-join");

interface AfpUrls {
  private: string;
  v1: string;
  v2: string;
}

const crmServices = {
  private: "https://api-s-uat.sgmarkets.com/services/ic/crm/private/v1",
  v2: "https://api-s-uat.sgmarkets.com/services/ic/crm/v2",
};
const crmServicesProd = {
  private: "https://api-s.sgmarkets.com/services/ic/crm/private/v1",
  v2: "https://api-s.sgmarkets.com/services/ic/crm/v2",
};

const contactDbServices = {
  private: "https://api-s-uat.sgmarkets.com/thirdparties/contacts/private/v1",
  v1: "https://api-s-uat.sgmarkets.com/thirdparties/contacts/v1",
  v2: "https://api-s-uat.sgmarkets.com/thirdparties/contacts/v1",
};

const contactDbServicesProd = {
  private: "https://api-s.sgmarkets.com/thirdparties/contacts/private/v1",
  v1: "https://api-s.sgmarkets.com/thirdparties/contacts/v1",
  v2: "https://api-s.sgmarkets.com/thirdparties/contacts/v1",
};

const uiServices = {
  private: "https://api-s-uat.sgmarkets.com/services/ic/crm/ui-private/v1",
};
const uiServicesProd = {
  private: "https://api-s.sgmarkets.com/services/ic/crm/ui-private/v1",
};

const afpDicIso = {
  crm: crmServices,
  contactDb: contactDbServices,
  ui: uiServices,
};
const afpDicProd = {
  crm: crmServicesProd,
  contactDb: contactDbServicesProd,
  ui: uiServicesProd,
};

export const getAfpRootUrl = (
  afpUrls: AfpUrls,
  relativeUrl: string
): string => {
  const icUiService = startsWith(relativeUrl, "/api");
  if (icUiService) {
    return urljoin(afpUrls.private, relativeUrl.replace("/api", ""));
  }
  const isV2Service = startsWith(relativeUrl, "/2.0");
  const isV1Service = startsWith(relativeUrl, "/1.0");
  const rootUrl = isV1Service
    ? afpUrls.v1
    : isV2Service
    ? afpUrls.v2
    : afpUrls.private;
  const inputUrl = isV1Service
    ? relativeUrl.replace("/1.0", "")
    : relativeUrl.replace(isV2Service ? "/2.0" : "/private/0.0", "");
  return urljoin(rootUrl, inputUrl);
};

export const afpUrlsFormat = (fullUrl: string, env: Env): string => {
  if (env !== "production" && env !== "iso") {
    return fullUrl;
  }
  return convert(fullUrl, env);
};

const convert = (fromUrl: string, env: string): string => {
  if (startsWith(fromUrl, privateServiceConfigUrls[env])) {
    const absolutePath = fromUrl.replace(privateServiceConfigUrls[env], "");
    const afpUrls = env === "production" ? afpDicProd.ui : afpDicIso.ui;
    return getAfpRootUrl(afpUrls as AfpUrls, absolutePath);
  }
  if (startsWith(fromUrl, contactDbConfigUrls[env])) {
    const absolutePath = fromUrl.replace(contactDbConfigUrls[env], "");
    const afpUrls =
      env === "production" ? afpDicProd.contactDb : afpDicIso.contactDb;
    return getAfpRootUrl(afpUrls, absolutePath);
  }
  if (startsWith(fromUrl, `${soaConfigUrls[env]}/api`)) {
    const absolutePath = fromUrl.replace(`${soaConfigUrls[env]}/api`, "");
    const afpUrls = env === "production" ? afpDicProd.crm : afpDicIso.crm;
    return getAfpRootUrl(afpUrls as AfpUrls, absolutePath);
  }
  if (startsWith(fromUrl, soaConfigUrls[env])) {
    const absolutePath = fromUrl.replace(soaConfigUrls[env], "");
    const afpUrls = env === "production" ? afpDicProd.crm : afpDicIso.crm;
    return getAfpRootUrl(afpUrls as AfpUrls, absolutePath);
  }
  return fromUrl;
};
