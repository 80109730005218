import { Component, h } from "preact";
import { Tag } from "Sgb4/Tag/Tag";
import { ClearButton } from "Sgb4/common/Buttons/ClearButton";
import { State, Status } from "common/types/global";
import { Select, LabelValue } from "Sgb4/common/Components/Select/Select";
import { SearchType } from "Sgb4/DeprecatedTagPicker/SingleSelect/TagPicker";
import {
  InvalidFeedback,
  getErrorClass,
} from "Sgb4/common/Components/InvalidFeedback";
import { ErrorProps } from "Sgb4/DeprecatedTagPicker/common/typings";
import { fromStyleLocals } from "common/css";
import * as stylesheet from "./InputTagList.less";
const css = fromStyleLocals(stylesheet.locals);

export interface DispatchProps<TItem> {
  onInput: (terms: string) => void;
  onRemoveTag: (item: TItem) => void;
  onClear: () => void;
  onFocus: () => void;
  onBlur?: () => void;
  onAddTag?: (terms: string) => void;
  onSelectChange?: (optionValue: SearchType) => void;
}

export interface FixedProps<TItem> extends ErrorProps {
  mapper: (item: TItem) => string;
  maxItemNameLength?: number;
  placeholder?: string;
}

export interface InputTagListStateProps<TItem> {
  disabled: boolean;
  tags: Array<TItem & { isReadonly: boolean }>;
  state: State;
  status: Status;
  terms: string;
  selectOptions?: Array<LabelValue<SearchType>>;
  displayClearButton?: boolean;
}

export type Props<TItem> = DispatchProps<TItem> &
  InputTagListStateProps<TItem> &
  FixedProps<TItem>;

export class InputTagList<TItem> extends Component<Props<TItem>> {
  constructor(props: Props<TItem>) {
    super(props);
    this.onInput = this.onInput.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  public render({
    terms,
    onRemoveTag,
    tags,
    onFocus,
    onClear,
    mapper,
    maxItemNameLength,
    placeholder,
    selectOptions,
    disabled,
    inError,
    errorMessage,
    displayClearButton = true,
  }: Props<TItem>) {
    return (
      <div class={`row ${css("input-tag-list")}`}>
        <style>{stylesheet.toString()}</style>
        <div class="col-xs-12 col-md">
          <div
            class={`input-group form-group form-control flex-wrap h-auto ${
              inError ? "mb-0" : ""
            } ${getErrorClass(inError)}`}
          >
            <div style={{ width: "90%" }} class="d-flex flex-wrap">
              {tags.map(tag => (
                <Tag
                  {...{ mapper, maxItemNameLength }}
                  onRemove={onRemoveTag}
                  item={tag}
                />
              ))}
              <input
                style={{ outline: "none" }}
                className="border-0 flex-grow-1 bg-transparent"
                onFocus={onFocus}
                placeholder={placeholder}
                disabled={disabled}
                onInput={this.onInput}
                value={terms}
                type="text"
              />
            </div>
            {displayClearButton && <ClearButton onClear={onClear} />}
          </div>
          {inError && <InvalidFeedback errorMessage={errorMessage} />}
        </div>
        {selectOptions && (
          <div class="col-xs-12 col-md-2">
            <Select<SearchType>
              options={selectOptions}
              onChange={this.onSelectChange}
            />
          </div>
        )}
      </div>
    );
  }

  private onInput(event: any) {
    const { onInput, onFocus } = this.props;
    onInput(event.target.value);
    onFocus();
  }

  private onSelectChange(optionValue: SearchType) {
    const { onSelectChange } = this.props;
    if (onSelectChange) {
      onSelectChange(optionValue);
    }
  }
}
