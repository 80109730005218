import { Component, h } from "preact";
import { ListItem } from "./ListItem";
import { Status } from "common/types/global";

export interface ListItemAutoCompleteStateProps<TItem> {
  items: TItem[];
  isVisible: boolean;
  status: Status;
  reason?: string;
  exclude: TItem[];
}

export interface ListItemAutocompleteFixedProps<TItem> {
  renderRow: (item: TItem) => JSX.Element;
  renderHeader: () => JSX.Element;
  headerHeight?: number;
}

export type ListItemAutocompleteProps<TItem> = ListItemAutoCompleteStateProps<
  TItem
> &
  ListItemAutocompleteFixedProps<TItem>;

export class ListItemAutocomplete<TItem> extends Component<
  ListItemAutocompleteProps<TItem>
> {
  constructor(props: ListItemAutocompleteProps<TItem>) {
    super(props);
  }

  public render({
    items,
    isVisible,
    renderRow,
    renderHeader,
    headerHeight,
  }: ListItemAutocompleteProps<TItem>) {
    return isVisible ? (
      <ListItem<TItem> {...{ renderRow, renderHeader, headerHeight, items }} />
    ) : null;
  }
}
