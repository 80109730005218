import { Env } from "../common/api/apiUrls";
import { isStringEqual } from "../Sgb4/common/strings/stringUtils";

const CREATE_CONTACT_LINK_PRD = "https://ic.sgmarkets.com/v2/contacts/new";
const CREATE_CONTACT_LINK_ISO = "https://ic-iso.sgmarkets.com/v2/contacts/new";
const CREATE_CONTACT_LINK_HOM =
  "https://ic-hom.fr.world.socgen/v2/contacts/new";

export const getCreateContactLink = (env: Env): string => {
  if (isStringEqual(env, "production")) {
    return CREATE_CONTACT_LINK_PRD;
  }
  if (isStringEqual(env, "iso")) {
    return CREATE_CONTACT_LINK_ISO;
  }
  return CREATE_CONTACT_LINK_HOM;
};
