export const CivilityTypes = {
  Mr: 1,
  Mrs: 2,
  Ms: 200000,
};

export const EmailTypeNames = {
  BloombergChat: "Bloomberg chat",
  Personal: "Personal",
  Professional: "Professional",
  ReutersChat: "Reuters chat",
  YahooChat: "Yahoo chat",
};

export const AddressTypes = ["Professional", "Personal"];

export const SelectClientMessage = "Please select a client";

export const GoogleApiKey: string = "AIzaSyB8QDQjug5IEiKApu3ZnfvaN-UAmb9bN2Y";

export const defaultFormatDate = "YYYY-MM-DD";

export const DealKPIColor = {
  green: "#1FD69F",
  lightBlue: "#A3DEE5",
  deepBlue: "#0D50C7",
  warning: "#FF7214",
  red: "#d53229",
};
