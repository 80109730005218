import { Component, h } from "preact";
import { VirtualList } from "./VirtualList/VirtualList";

export interface ListItemProps<TItem> {
  items: TItem[];
  renderRow: (item: TItem) => JSX.Element;
  renderHeader?: () => JSX.Element;
  rowHeight?: number;
  headerHeight?: number;
}

export class ListItem<TItem> extends Component<ListItemProps<TItem>> {
  constructor(props: ListItemProps<TItem>) {
    super(props);
  }

  public render({
    rowHeight,
    items,
    renderRow,
    renderHeader,
    headerHeight,
  }: ListItemProps<TItem>) {
    return (
      <VirtualList
        sync
        class={`card card-combobox shadow-lg d-block position-absolute w-100 mt-n3`}
        data={items}
        rowHeight={rowHeight || 50}
        renderRow={renderRow}
        renderHeader={renderHeader}
        headerHeight={headerHeight}
      />
    );
  }
}
